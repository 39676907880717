import React from "react";
import { TextField, Box } from "@mui/material";
function MultilineInputField({
  size,
  formik,
  label = "",
  name,
  noValidationText,
  sx,
  ...props
}) {
  return (
    <div>
      <Box>
        <TextField
          multiline
          rows={6}
          sx={{
            fontFamily: "Raleway, sans-serif",
            fontSize: "18px",
            ".MuiInputBase-input": {
              fontFamily: "Raleway, sans-serif",
              fontSize: "18px",
              //   padding: 0,
              color: "white",
              textAlign: "center",
              "::placeholder": {
                fontFamily: "Raleway, sans-serif",
                fontSize: "18px",
                color: "#fff !important",
                textAlign: "center",
              },
            },
            ".MuiOutlinedInput-root": {
              borderRadius: "8px !important",
              textAlign: "center",
              boxShadow: " 0px 4px 6px 0px rgba(0,0,0,0.3)",
              bgcolor: "#26B3F0",
            },
            borderRadius: "5px !important",
            color: "white",
            width: "100%",
          }}
          {...props}
          id={name}
          name={name}
          label={label}
          value={formik?.values[name]}
          onChange={formik?.handleChange}
          onBlur={formik?.handleBlur}
          error={formik?.touched[name] && Boolean(formik.errors[name])}
          helperText={
            !noValidationText && formik?.touched[name] && formik?.errors[name]
          }
        />
      </Box>
    </div>
  );
}

export default MultilineInputField;
