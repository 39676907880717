import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Admin from "../screens/Admin";
import SignUp from "../components/Auth/SignUp";
import SignIn from "../components/Auth/SignIn";
import ForgetPassword from "../components/Auth/ForgetPassword";
import Loader from "../utils/Loader";
const Buyer = lazy(() => import("../screens/Buyer"));
function AppRoutes() {
  return (
    <React.Suspense fallback={<Loader active={true} />}>
      <Routes>
        {/* <Navigate from="/" to="/auth" /> */}
        <Route path="/auth/signup" element={<SignUp />} />
        <Route path="/auth/signin" element={<SignIn />} />
        <Route path="/auth/forget-password" element={<ForgetPassword />} />
        <Route
          exact
          path="/"
          element={<Navigate replace to="/auth/signin" />}
        />
        <Route path="/admin/*" element={<Admin />} />
        <Route path="/buyer/*" element={<Buyer />} />
      </Routes>
    </React.Suspense>
  );
}
export default AppRoutes;
