import axios from "../axiosConfig";

class Routes {
  addProduct(data) {
    return axios.post("/admin/product", data);
  }
  editProduct(id, data) {
    return axios.post(`/admin/product/${id}`, data);
  }
  deleteProduct(id) {
    return axios.delete(`/admin/product/${id}`);
  }
  allSoldProducts() {
    return axios.get("/admin/product");
  }
  changeProductStatus(id) {
    return axios.put(`/admin/set-status/${id}`);
  }
  setting(id, data) {
    return axios.post(`/admin/set-product-time/${id}`, data);
  }
}

export default new Routes();
