import axios from "../axiosConfig";

class Routes {
  addCategory(data) {
    return axios.post("/admin/category", data);
  }
  editCategory(data, id) {
    console.log("AXIOS", data);
    return axios.post(`/admin/category/${id}`, data);
  }
  deleteCategory(id) {
    return axios.delete(`/admin/category/${id}`);
  }
  allCategories() {
    return axios.get("/admin/category");
  }
  productsByCategories(id) {
    return axios.get(`/admin/all-products/${id}`);
  }
  allProductsByCategories(id) {
    return axios.get(`/admin/all-products`);
  }
}

export default new Routes();
