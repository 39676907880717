import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useNavigate, useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { Button, Grid } from "@mui/material";
import userImg from "../../../assets/images/userImg.png";
import userProfile from "../../../assets/images/userProfile.png";
import { AuthAPI } from "../../../api";
import { toast } from "react-toastify";
const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  let navigate = useNavigate();
  let location = useLocation();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const Tabs = [
    { title: "Add Product", to: "/add-product" },
    { title: "My Products", to: "/my-products" },
    { title: "Payment Gateways", to: "/payment-gateways" },
    { title: "Auction Categories", to: "/auction-categories" },
    { title: "Auction Settings", to: "/auction-settings" },
    { title: "Buyer/Seller", to: "/buyer-sellers" },
    { title: "View Bids", to: "/view-bids" },
    { title: "View Auction", to: "/view-auction" },
    { title: "view Transactions", to: "/view-transactions" },
  ];

  function Logout() {
    AuthAPI.logout().then((res) => {
      localStorage.removeItem("user");
      toast.success("Logout Successfully");
      navigate("/");
    });
  }

  const drawer = (
    <Box className="centerRow">
      <Box
        sx={{
          position: "relative",
          width: { xs: "100%", sm: "90%", md: "65%", lg: "60", xl: "50%" },
          background: { xs: "#fff", sm: "#ccefff !important" },
          py: { xs: 6, md: 7 },
          px: { xs: 2, md: 7 },
          mx: { sm: 5, md: 10, lg: 25 },
          my: { xs: 0, sm: 2 },
          borderRadius: "10px",
          boxShadow: { xs: "none", sm: "0px 0px 4px 0px rgba(0, 0, 0, 0.6)" },
        }}
      >
        <Grid container spacing={1}>
          {Tabs.map((tab, index) => (
            <Grid
              className={"centerRow"}
              sx={{
                display: "flex",
                justifyContent: { xs: "flex-start", sm: "center" },
              }}
              item
              xs={18}
              sm={4}
              md={4}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setMobileOpen(false);
                  navigate(`/admin${tab.to}`);
                }}
                boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.6)"
                sx={{
                  width: { xs: 200, sm: 200 },
                  bgcolor:
                    location.pathname == `/admin${tab.to}`
                      ? "primary"
                      : "#26B3F0",
                  py: 1,
                  fontFamily: "poppins !important",
                }}
              >
                {tab.title}
              </Button>
            </Grid>
          ))}
          <Grid item xs={12}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                // position: "relative",
              }}
            >
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#FFB501",
                  color: "white",
                  width: { xs: "50%", md: "10%" },
                  position: "absolute",
                  bottom: -17,
                  right: 30,
                  "&:hover": {
                    // transform: "scale(1.1)",
                    bgcolor: "#FFB501",
                  },
                  fontSize: 13,
                  px: 1,
                  fontFamily: "poppins !important",
                }}
                onClick={() => Logout()}
              >
                Logout
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", flexDirection: { xs: "column" } }}>
      <CssBaseline />

      <Box sx={{ display: "flex", justifyContent: "flex-start " }}>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" }, pl: 4 }}
        >
          <MenuIcon />
        </IconButton>
      </Box>

      <Box
        component="nav"
        sx={{
          width: { sm: "100%" },
          flexShrink: { sm: 0 },
        }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            width: { xs: "100%" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Box
          sx={{
            display: { xs: "none", sm: "block" },
            // position: "fixed",
            bgcolor: "white",
            zIndex: 99,
          }}
        >
          {drawer}
        </Box>
      </Box>
      <Box
        // component="main"
        sx={{
          flexGrow: 1,
          px: { xs: 0, md: 7 },
          // pt: { xs: 1, sm: 35, md: 30 },

          width: { xs: "100%", sm: "100%" },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
