import React, { useState, useEffect } from "react";
import { Box, Button, Paper, Typography, Grid } from "@mui/material";
import userImg from "../../../assets/images/person.png";
import master from "../../../assets/images/master.png";
import visa from "../../../assets/images/visa.png";
import zain from "../../../assets/images/zain.png";
const { REACT_APP_HOST } = process.env;

function BuyerCard({ item }) {
  let transaction = item?.item;
  let buyer = item?.buyer;
  useEffect(() => {
    console.log("trans Item", item);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Paper
        sx={{
          width: { xs: "290px", md: "280px", lg: "290px" },
          height: { xs: "290px", md: "280px", lg: "290px" },
          bgcolor: "#53CBFF",
          border: "1px solid #26B3F0",
          // mx: 4,
          pt: 2,
          borderRadius: "14px",
        }}
        elevation={8}
      >
        <Grid container>
          <Grid item className={"centerRow"} xs={12}>
            <Box
              sx={{ width: "50px", height: "50px", mt: 1 }}
              component="img"
              src={
                buyer?.image?.url
                  ? `${REACT_APP_HOST}${buyer?.image?.url}`
                  : userImg
              }
            />
          </Grid>
          <Grid
            item
            className={"centerRow"}
            sx={{ color: "red", textAlign: "center", pt: 0.5 }}
            xs={12}
          >
            <Typography fontFamily="Raleway, sans-serif" color="#fff">
              {buyer?.username}
            </Typography>
          </Grid>
          <Grid
            item
            className={"centerRow"}
            sx={{ color: "white", textAlign: "center", pt: 0.5 }}
            xs={12}
          >
            <Typography fontFamily="Raleway, sans-serif">
              {buyer?.email}
            </Typography>
          </Grid>
          {/* <Grid
            item
            className={"centerRow"}
            sx={{ color: "white", textAlign: "center", pt: 0.5 }}
            xs={12}
          >
            <Typography fontFamily="Raleway, sans-serif">
              Bought Products: 4
            </Typography>
          </Grid> */}
          <Grid
            item
            className={"centerRow"}
            sx={{ color: "white", textAlign: "center", pt: 0.5 }}
            xs={12}
          >
            <Typography fontFamily="Raleway, sans-serif">
              Paid Amount: {transaction?.amount}
            </Typography>
          </Grid>
          <Grid
            item
            className={"centerRow"}
            sx={{ color: "white", textAlign: "center", pt: 0.5, mt: 2 }}
            xs={12}
          >
            <Typography fontFamily="Raleway, sans-serif">
              Used Gateways
            </Typography>
          </Grid>
          <Grid item className={"centerRow"} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Box
                sx={{ width: "75px", height: "40px", mt: 1 }}
                component="img"
                src={
                  transaction?.used_gateway == "Visa"
                    ? visa
                    : transaction?.used_gateway == "Master"
                    ? master
                    : transaction?.used_gateway == "ZainCash"
                    ? zain
                    : null
                }
              />
              {/* <Box
                sx={{ width: "75px", height: "40px", mt: 1 }}
                component="img"
                src={visa}
              />
              <Box
                sx={{ width: "75px", height: "40px", mt: 1 }}
                component="img"
                src={zain}
              /> */}
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}

export default BuyerCard;
