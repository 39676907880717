import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import Residence from "../../../assets/images/Residence.png";
import Commercial from "../../../assets/images/Commercial.png";
import car from "../../../assets/images/car.png";
import shop from "../../../assets/images/shop.png";
import genral from "../../../assets/images/genral.png";
import mechine from "../../../assets/images/mechine.png";
import Subheading from "../../../utils/HeadingAdmin";
import CurrentBids from "./CurrentBids";
import EndedBids from "./EndedBids";
import { CategoryAPI } from "../../../api";
import Loader from "../../../utils/Loader";
const { REACT_APP_HOST } = process.env;

let Cats = [
  {
    image: Commercial,
    title: "Commercial",
    count: "99+",
  },
  {
    image: Residence,
    title: "Residence",
    count: "233",
  },

  {
    image: car,
    title: "Cars",
    count: "12",
  },
  {
    image: shop,
    title: "Shops",
    count: "99",
  },
  {
    image: genral,
    title: "Genral Items",
    count: "233",
  },
  {
    image: mechine,
    title: "Mechinery",
    count: "1",
  },
];

function ViewBids() {
  const [selected, setSelected] = useState(1);
  const [tab, setTab] = useState(1);
  const [categories, setCategories] = useState([]);
  const [productsByCat, setProductsByCat] = useState([]);
  const [endedBids, setEndedBids] = useState([]);
  const [currentBids, setCurrentBids] = useState([]);
  const [unWonBids, setunWonBids] = useState([]);
  const [wonBids, setWonBids] = useState([]);
  const [isWon, setisWon] = useState(false);
  const [bidWon, setBidWon] = useState(false);

  const [isLoading, setisLoading] = useState(true);
  useEffect(() => {
    setunWonBids([]);
    CategoryAPI.productsByCategories(selected).then((res) => {
      setisLoading(false);
      setWonBids(res?.data.data.sold);
    });

    CategoryAPI.allCategories().then((res) => {
      setisLoading(false);
      setCategories(res.data.data);
      let selectedCat = res?.data.data?.filter((item) => item.id == selected);
      // console.log("newArr", selectedCat);
      setProductsByCat(selectedCat);
      //un won bids
      selectedCat[0]?.products?.map((product, index) => {
        setisWon(false);
        return product?.bids?.map((bid, index) => {
          if (bid.is_won == 1) {
            // console.log("is won pro", product);
            setisWon(true);
          }
          if (
            index == product?.bids?.length - 1 &&
            bid.is_won == 0 &&
            isWon == false
          ) {
            // console.log("un won product", product);
            setunWonBids((pre) => [...pre, product]);
          }
        });
      });

      //Ended Bids
      let endBids = selectedCat[0]?.products?.map((product) =>
        product.bids.filter((bid) => bid.is_won == 1)
      );
      endBids = endBids.filter((bid) => bid.length);

      setEndedBids(endBids);
      // console.log("endBids", endBids);

      //current Bids
      // let currentBids = selectedCat[0]?.products?.map((product) =>
      //   product.bids.filter((bid) => bid.is_won == 0)
      // );
      // currentBids = currentBids.filter((bid) => bid.length);
      // setCurrentBids(currentBids);
      // console.log("currentBids", currentBids);
    });
  }, []);
  useEffect(() => {
    setBidWon(false);
    setisLoading(true);
    setunWonBids([]);
    CategoryAPI.productsByCategories(selected).then((res) => {
      setisLoading(false);
      setWonBids(res?.data.data.sold);
    });
    CategoryAPI.allCategories().then((res) => {
      setisLoading(false);
      setCategories(res.data.data);
      let selectedCat = res?.data.data?.filter((item) => item.id == selected);
      setProductsByCat(selectedCat);

      // console.log("selectedCat", selectedCat);

      //un won bids
      selectedCat[0]?.products?.map((product, index) => {
        setisLoading(false);
        setisWon(false);
        return product?.bids?.map((bid, index) => {
          if (bid.is_won == 1) {
            // console.log("is won pro", product);
            setisWon(true);
          }
          if (
            index == product?.bids?.length - 1 &&
            bid.is_won == 0 &&
            isWon == false
          ) {
            // console.log("un won product", product);
            setunWonBids((pre) => [...pre, product]);
          }
        });
      });

      //Ended Bids
      let endBids = selectedCat[0]?.products?.map((product) =>
        product.bids.filter((bid) => bid.is_won == 1)
      );
      endBids = endBids.filter((bid) => bid.length);

      setEndedBids(endBids);
      // console.log("endBids", endBids);

      //current Bids
      // let currentBids = selectedCat[0]?.products?.map((product) =>
      //   product.bids.filter((bid) => bid.is_won == 0)
      // );
      // currentBids = currentBids.filter((bid) => bid.length);
      // setCurrentBids(currentBids);
      // console.log("currentBids", currentBids);
    });
  }, [selected, bidWon]);
  return (
    <Box height="100%" pb={5} width="100%">
      <Loader active={isLoading} />

      <Box className={"centerRow"} sx={{ pb: 5 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 26, md: 22, lg: 24 },
            color: "#024861",
            fontFamily: "poppins",
            mt: 8,
          }}
        >
          Select Category
        </Typography>
      </Box>
      <Grid zIndex={5} container spacing={2} px={"5%"}>
        {categories?.map((cat, index) => {
          return (
            <Grid
              item
              key={cat?.name}
              xs={6}
              sm={4}
              md={4}
              lg={2}
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems="center"
            >
              <Paper
                elevation={3}
                sx={{
                  cursor: "pointer",
                  width: {
                    xs: "80px",
                    md: selected == cat?.id ? "140px" : "120px",
                  },
                  height: {
                    xs: "80px",
                    md: selected == cat?.id ? "140px" : "120px",
                  },
                  bgcolor:
                    selected == cat?.id
                      ? "rgba(61,197, 255,0.75) "
                      : "rgba(158,228, 253,0.4) !important",
                  borderRadius: "10px",
                  position: "relative",
                  p: "5%",
                }}
                onClick={() => {
                  // navigate("/auction");
                  setSelected(cat?.id);
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    height: "100%",
                    // width: { xs: "50%", sm: "60%", md: "100%" },
                    // heigth: { xs: "50%", sm: "60%", md: "100%" },
                    display: "block",
                    overflow: "hidden",
                    objectFit: "contain",
                  }}
                  src={`${REACT_APP_HOST}${cat?.image?.url}`}
                />
              </Paper>
              <Typography
                // variant="h5"
                // align="center"
                color="#024861"
                fontFamily="Raleway, sans-serif"
                fontWeight="bold"
                sx={{ fontSize: { xs: 18, md: 16, xl: 28 }, py: 2 }}
              >
                {cat?.name}
              </Typography>
            </Grid>
          );
        })}
        <Grid items xs="12">
          <Box className={"centerRow"} py={5}>
            <Box
              sx={{
                width: { xs: "100%", sm: "30%" },
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: { xs: "center", md: "space-around" },
                alignItems: "center",
                mb: { xs: 1, sm: 5 },
              }}
            >
              <Button
                onClick={() => setTab(1)}
                sx={{
                  fontFamily: "poppins",
                  // width: { xs: "100%", md: "60%" },
                  width: { xs: "50%", sm: "138px" },
                  height: "40px",
                  borderRadius: { xs: "8px", sm: "10px" },
                  fontSize: { xs: 12, md: 10, lg: 14 },
                  bgcolor: tab == 1 ? "#0C88BD" : "#26B3F0",
                  mb: { xs: 1, md: 0 },
                }}
                variant="contained"
              >
                Current Bids
              </Button>
              <Button
                onClick={() => setTab(2)}
                sx={{
                  fontFamily: "poppins",
                  // width: { xs: "100%", md: "60%" },
                  width: { xs: "50%", sm: "138px" },
                  height: "40px",
                  borderRadius: { xs: "8px", sm: "10px" },
                  fontSize: { xs: 12, md: 10, lg: 14 },
                  bgcolor: tab == 2 ? "#0C88BD" : "#26B3F0",
                  mb: { xs: 1, md: 0 },
                }}
                variant="contained"
              >
                Ended Bids
              </Button>
            </Box>
          </Box>
          <React.Suspense fallback={<Loader />}>
            {tab == 1 ? (
              <CurrentBids
                setBidWon={setBidWon}
                unWonBids={[...new Set(unWonBids)]}
              />
            ) : (
              <EndedBids wonBids={wonBids} />
            )}
          </React.Suspense>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ViewBids;
