import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Paper, Button } from "@mui/material";
import Residence from "../../../assets/images/Residence.png";
import Commercial from "../../../assets/images/Commercial.png";
import car from "../../../assets/images/car.png";
import shop from "../../../assets/images/shop.png";
import genral from "../../../assets/images/genral.png";
import mechine from "../../../assets/images/mechine.png";
import Sold from "./Sold";
import InList from "./InList";
import { CategoryAPI } from "../../../api";
import Loader from "../../../utils/Loader";
const { REACT_APP_HOST } = process.env;

let Cats = [
  {
    image: Commercial,
    title: "Commercial",
    count: "99+",
  },
  {
    image: Residence,
    title: "Residence",
    count: "233",
  },

  {
    image: car,
    title: "Cars",
    count: "12",
  },
  {
    image: shop,
    title: "Shops",
    count: "99",
  },
  {
    image: genral,
    title: "Genral Items",
    count: "233",
  },
  {
    image: mechine,
    title: "Mechinery",
    count: "1",
  },
];

function ViewBids() {
  const [selected, setSelected] = useState(1);
  const [tab, setTab] = useState(1);
  const [categories, setCategories] = useState([]);
  const [productsByCat, setProductsByCat] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [ischanged, setisChanged] = useState(false);

  useEffect(() => {
    CategoryAPI.allCategories().then((res) => {
      setCategories(res.data.data);
      // let newArr = res?.data.data?.filter((item) => item.id == selected);
      // setProductsByCat(newArr);
      setisLoading(false);
    });
    CategoryAPI.productsByCategories(selected).then((res) => {
      setProductsByCat(res.data.data);
      setisLoading(false);
    });
  }, []);

  useEffect(() => {
    setisChanged(false);
    CategoryAPI.allCategories().then((res) => {
      setCategories(res.data.data);
      // let newArr = res?.data.data?.filter((item) => item.id == selected);
      // setProductsByCat(newArr);
      setisLoading(false);
    });
    CategoryAPI.productsByCategories(selected).then((res) => {
      setProductsByCat(res.data.data);
      setisLoading(false);
    });
  }, [ischanged, selected]);
  // useEffect(() => {
  //   setisLoading(true);
  //   CategoryAPI.productsByCategories(selected).then((res) => {
  //     setProductsByCat(res.data.data);
  //     setisLoading(false);
  //   });
  // }, [selected]);

  return (
    <Box height="100%" pb={5} width="100%">
      <Box className={"centerRow"} sx={{ pb: 5 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 26, md: 22, lg: 24 },
            color: "#024861",
            fontFamily: "poppins",
            pt: { xs: 1, md: 2, lg: 7, xl: 9 },
          }}
        >
          Categories
        </Typography>
      </Box>
      <Grid container spacing={2} px={"5%"}>
        {categories?.map((cat, index) => {
          return (
            <Grid
              item
              key={cat?.name}
              xs={6}
              sm={4}
              md={4}
              lg={2}
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems="center"
            >
              <Paper
                elevation={3}
                sx={{
                  cursor: "pointer",
                  width: {
                    xs: "80px",
                    md: selected == cat?.id ? "140px" : "120px",
                  },
                  height: {
                    xs: "80px",
                    md: selected == cat?.id ? "140px" : "120px",
                  },
                  bgcolor:
                    selected == cat?.id
                      ? "rgba(61,197, 255,0.75) "
                      : "rgba(158,228, 253,0.4) !important",
                  borderRadius: "10px",
                  position: "relative",
                  p: "5%",
                }}
                onClick={() => {
                  // navigate("/auction");
                  setSelected(cat?.id);
                }}
              >
                <Box
                  sx={{
                    width: { xs: "40px", md: "50px" },
                    height: { xs: "40px", md: "50px" },
                    borderRadius: "50%",
                    bgcolor: "red",
                    position: "absolute",
                    bgcolor: "rgba(38,179, 240,1) !important",
                    transformOrigin: "90% 40% !important",
                    top: -20,
                    right: -20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "white" }}>
                    {cat?.products?.length}
                  </Typography>
                </Box>
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    height: "100%",
                    // width: { xs: "50%", sm: "60%", md: "100%" },
                    // heigth: { xs: "50%", sm: "60%", md: "100%" },
                    display: "block",
                    overflow: "hidden",
                    objectFit: "contain",
                  }}
                  src={`${REACT_APP_HOST}${cat?.image?.url}`}
                />
              </Paper>
              <Typography
                // variant="h5"
                // align="center"
                color="#024861"
                fontFamily="Raleway, sans-serif"
                fontWeight="bold"
                sx={{ fontSize: { xs: 18, md: 16, xl: 28 }, py: 2 }}
              >
                {cat?.title}
              </Typography>
            </Grid>
          );
        })}
        <Grid items xs="12">
          <Box width="100%" className={"centerRow"} py={5}>
            <Box
              sx={{
                width: { xs: "100%", sm: "30%" },
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                justifyContent: { xs: "center", md: "space-around" },
                alignItems: "center",
                mb: { xs: 1, sm: 5 },
              }}
            >
              <Button
                onClick={() => setTab(1)}
                sx={{
                  fontFamily: "poppins",
                  // width: { xs: "100%", md: "60%" },
                  width: { xs: "50%", sm: "138px" },
                  height: "40px",
                  borderRadius: { xs: "8px", sm: "10px" },
                  fontSize: { xs: 12, md: 10, lg: 14 },
                  bgcolor: tab == 1 ? "#007E3A" : "#FFB501",
                  mb: { xs: 1, md: 0 },
                }}
                variant="contained"
              >
                Sold
              </Button>
              <Button
                onClick={() => setTab(2)}
                sx={{
                  fontFamily: "poppins",
                  // width: { xs: "100%", md: "60%" },
                  width: { xs: "50%", sm: "138px" },
                  height: "40px",
                  borderRadius: { xs: "8px", sm: "10px" },
                  fontSize: { xs: 12, md: 10, lg: 14 },
                  bgcolor: tab == 2 ? "#007E3A" : "#FFB501",
                  mb: { xs: 1, md: 0 },
                }}
                variant="contained"
              >
                On List
              </Button>
            </Box>
          </Box>
          <React.Suspense
            fallback={<Box bgcolor="red" width="100%" height="300px"></Box>}
          >
            {/* {console.log("productsByCat", productsByCat)} */}
            {productsByCat && (
              <>
                {tab == 1
                  ? productsByCat?.sold && (
                      <Sold
                        cat={selected}
                        sold={productsByCat?.sold}
                        setisChanged={setisChanged}
                      />
                    )
                  : productsByCat?.unsold && (
                      <InList
                        cat={selected}
                        unsold={productsByCat?.unsold}
                        setisChanged={setisChanged}
                      />
                    )}
              </>
            )}
            <Loader active={isLoading} />
          </React.Suspense>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ViewBids;
