import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
//ASSETS
// import { AddIcon } from "../../../assets/assets";
import AddIcon from "../../../assets/images/addImg.png";

const ImageBox = ({ imageIndex, handleImages, setImages, noOfImages }) => {
  //   const [imageCount, setImageCount] = React.useState(3);
  const [avatar, setAvatar] = React.useState(null);
  const handleChange = (e) => {
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.addEventListener("load", (e) => {
      setAvatar(e.target.result);
      setImages((prevImages) => {
        // console.table("card", [file]);
        let data = e.target.result.split(",")[1];
        let temp = [...prevImages, data];
        // temp[imageIndex] = file;
        console.log("temp", temp);
        return temp;
      });
    });
    reader.removeEventListener("load", (e) => setAvatar(e.target.result));
    // handleImages(e.target.files[0], imageIndex);
  };

  return (
    <Box
      component="label"
      title="add photo"
      sx={{
        ".MuiBox-root": {
          cursor: "pointer",
        },
      }}
      cursor="pointer"
    >
      <Box
        component="img"
        alt="add image icon"
        height="120px"
        minWidth="120px"
        src={avatar ?? AddIcon}
        sx={avatar ? style.ImageAvatar : style.ImageDefault}
        cursor="pointer"
      />
      <input
        multiple
        onChange={(e) => handleChange(e)}
        hidden
        type="file"
        accept="image/jpeg"
        style={{ cursor: "pointer" }}
      />
    </Box>
  );
};
const AddImage = ({ setImages, noOfImages }) => {
  //   const [images, setImages] = React.useState([]);
  const [imageCount, setImageCount] = React.useState(
    noOfImages ? noOfImages : 5
  );
  const handleImageCount = () => {
    setImageCount((prevState) => prevState + 1);
  };
  const handleImages = (file, imageIndex) => {
    // return setImages([...file]);
    setImages((prevImages) => {
      console.table("card", [file]);
      let temp = [...prevImages, file];
      // temp[imageIndex] = file;
      console.log("temp", temp);
      return temp;
    });
  };
  //   React.useEffect(() => {
  //     console.log("Images", images);
  //   }, [images.length]);
  return (
    <Grid container rowSpacing={2} sx={style.container}>
      {[...Array(imageCount)].map((value, index) => (
        <Grid item xs={4} md={2.4} sx={style.item}>
          <ImageBox
            key={index}
            imageIndex={index}
            handleImages={handleImages}
            setImages={setImages}
            noOfImages={noOfImages}
          />
        </Grid>
      ))}
      {/* <Grid item xs={3}>
        <IconButton sx={style.addMoreButton} onClick={() => handleImageCount()}>
          <Typography variant="subtitle1" color="primary">
            Add more...
          </Typography>
        </IconButton>
      </Grid> */}
    </Grid>
  );
};
const style = {
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // gap: "10px",
    // px: { xs: "10px" },
  },
  item: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addMoreButton: {
    height: { xs: "60px", sm: "100px" },
    width: { xs: "60px", sm: "100px" },
    display: "flex",
    alignItems: "center",
    mx: "5px",
  },
  ImageContainer: {
    height: { xs: "60px", sm: "100px" },
    width: { xs: "60px", sm: "100px" },
    border: "5px solid #3C176B",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  ImageDefault: {
    // height: "40px",
    width: { xs: "20px", sm: "40px" },
  },
  ImageAvatar: {
    height: { xs: "60px", sm: "100px" },
    width: { xs: "60px", sm: "100px" },

    borderRadius: "10px",
  },
};
export default AddImage;
