import React, { useState, useEffect } from "react";
import {
  Grid,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  Input,
  Select,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import bgImg from "../../assets/images/HeaderBg.png";
import blurbox from "../../assets/images/signup.png";
import fb from "../../assets/images/fb.png";
import google from "../../assets/images/google.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { AuthAPI, GenralAPI } from "../../api";

function SignUp() {
  let Navigate = useNavigate();
  let [codes, setCodes] = useState([]);
  const [text, setText] = useState("");
  const [cities, setCities] = useState([]);

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const [con_values, setConValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleConChange = (prop) => (event) => {
    setConValues({ ...con_values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleClickShowConPassword = () => {
    setConValues({
      ...con_values,
      showPassword: !con_values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleMouseDownConPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    GenralAPI.cities().then((res) => setCities(res?.data.data));
  }, []);

  const initialValues = {
    email: "",
    username: "",
    password: "",
    password_confirmation: "",
    city_id: 1,
    // countryCode: 1,
    zip_code: "",
    phone_number: "",
    role: "buyer",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required"),
    username: Yup.string().required("User Name is required"),
    password: Yup.string().required("Password is required"),
    password_confirmation: Yup.string()
      .required("Confirm Password is required")
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    city_id: Yup.string().required("city is required"),
    // countryCode: Yup.string().required("Country Code is required"),
    zip_code: Yup.string().required("Code is required"),
    phone_number: Yup.string().required("phone_number is required"),
  });

  const onSubmit = (values) => {
    AuthAPI.register(values).then((res) => {
      Navigate("/auth/signin");
    });
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    let res = axios.get("https://restcountries.com/v2/all").then((res) => {
      let srotedArray = res?.data?.sort(
        (a, b) => parseFloat(a.callingCodes) - parseFloat(b.callingCodes)
      );
      let uniqueKeys = [
        ...new Set(srotedArray.map((item) => parseFloat(item.callingCodes))),
      ];
      // console.log(arr, "arr");
      setCodes(uniqueKeys);
    });
  }, []);

  return (
    <Box
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: 0,
        height: "100vh",
        position: "relative",
        backgroundImage: `url(${bgImg})`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          position: "absolute",
          //   top: 0,
          bgcolor: "rgba(61,197,255,0.3)",
        }}
      >
        <Grid container>
          <Grid
            sx={{
              height: "100vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={0}
            md={6}
          >
            <Box
              sx={{
                width: "310px",
                minHeight: "240px",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundImage: `url(${blurbox})`,
                position: "relative",
                display: { md: "block" },
              }}
            ></Box>
          </Grid>
          <Grid
            item
            sx={{
              height: { xs: "100%", md: "100vh" },
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            md={6}
          >
            <Box
              component="form"
              sx={{
                overflowY: "scroll",
                bgcolor: "rgba(61,197,255,0.88)",
                width: { xs: "100%", md: "450px" },
                height: { xs: "100%", md: "95vh" },
                borderRadius: { xs: 0, md: "22px" },
                "&::-webkit-scrollbar": {
                  width: "0px",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 0px #B9EDFF",
                  webkitBoxShadow: "inset 0 0 0px #26B3F0",
                  border: "0px solid #26B3F0",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#26B3F0",
                  outline: "0px solid slategrey",
                },
              }}
            >
              <Grid sx={{ pt: 4, px: { xs: 4, md: 8 } }} container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CloseIcon
                            onClick={() => {
                              setText("");
                            }}
                            sx={{ fill: "red", pr: 1, cursor: "pointer" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "100%",
                      fontFamily: "Poppins",
                      ".MuiInputBase-input": {
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        padding: 0,
                        pb: 1,
                        pl: 2,
                        color: "white",
                        textAlign: "left",
                        "::placeholder": {
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          color: "rgba(255,255,255,1) !important",

                          textAlign: "left",
                        },
                      },
                      ".MuiInput-underline": {
                        "::before": {
                          borderBottom: "2px solid #fff !important",
                        },
                      },
                      ".MuiOutlinedInput-root ": {
                        py: "12px !important",
                        // px: "72px !important",
                        borderRadius: "8px !important",
                        textAlign: "left",
                        bgcolor: "#26B3F0",
                        boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.4)",
                      },
                      borderRadius: "5px !important",
                      color: "white",
                      textAlign: "center",
                    }}
                    placeholder="Enter Your Email Here"
                    variant="standard"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CloseIcon
                            sx={{ fill: "red", pr: 1, cursor: "pointer" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      width: "100%",
                      fontFamily: "Poppins",
                      ".MuiInputBase-input": {
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        padding: 0,
                        pb: 1,
                        pl: 2,
                        color: "white",
                        textAlign: "left",
                        "::placeholder": {
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          color: "rgba(255,255,255,1) !important",

                          textAlign: "left",
                        },
                      },
                      ".MuiInput-underline": {
                        "::before": {
                          borderBottom: "2px solid #fff !important",
                        },
                      },
                      ".MuiOutlinedInput-root ": {
                        py: "12px !important",
                        // px: "72px !important",
                        borderRadius: "8px !important",
                        textAlign: "left",
                        bgcolor: "#26B3F0",
                        boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.4)",
                      },
                      borderRadius: "5px !important",
                      color: "white",
                      textAlign: "center",
                    }}
                    placeholder="Enter Your Username"
                    variant="standard"
                    name="username"
                    value={formik.values.username}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.username && Boolean(formik.errors.username)
                    }
                    helperText={
                      formik.touched.username && formik.errors.username
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      width: "100%",
                      fontFamily: "Poppins",
                      ".MuiInputBase-input": {
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        padding: 0,
                        pb: 1,
                        pl: 2,
                        color: "white",
                        textAlign: "left",
                        "::placeholder": {
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          color: "rgba(255,255,255,1) !important",

                          textAlign: "left",
                        },
                      },
                      ".MuiInput-underline": {
                        "::before": {
                          borderBottom: "2px solid #fff !important",
                        },
                      },
                      ".MuiOutlinedInput-root ": {
                        py: "12px !important",
                        // px: "72px !important",
                        borderRadius: "8px !important",
                        textAlign: "left",
                        bgcolor: "#26B3F0",
                        boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.4)",
                      },
                      borderRadius: "5px !important",
                      color: "white",
                      textAlign: "center",
                    }}
                    variant="standard"
                  >
                    <Input
                      placeholder="Password"
                      id="standard-adornment-password"
                      type={values.showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            sx={{ color: "white" }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password &&
                        Boolean(formik.errors.password)
                      }
                      helperText={
                        formik.touched.password && formik.errors.password
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    sx={{
                      width: "100%",
                      fontFamily: "Poppins",
                      ".MuiInputBase-input": {
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        padding: 0,
                        pb: 1,
                        pl: 2,
                        color: "white",
                        textAlign: "left",
                        "::placeholder": {
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          color: "rgba(255,255,255,1) !important",

                          textAlign: "left",
                        },
                      },
                      ".MuiInput-underline": {
                        "::before": {
                          borderBottom: "2px solid #fff !important",
                        },
                      },
                      ".MuiOutlinedInput-root ": {
                        py: "12px !important",
                        // px: "72px !important",
                        borderRadius: "8px !important",
                        textAlign: "left",
                        bgcolor: "#26B3F0",
                        boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.4)",
                      },
                      borderRadius: "5px !important",
                      color: "white",
                      textAlign: "center",
                    }}
                    variant="standard"
                  >
                    <Input
                      placeholder="Confirm Password"
                      id="standard-adornment-password"
                      type={con_values.showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            sx={{ color: "white" }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConPassword}
                            onMouseDown={handleMouseDownConPassword}
                          >
                            {con_values.showPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                      name="password_confirmation"
                      value={formik.values.password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.password_confirmation &&
                        Boolean(formik.errors.password_confirmation)
                      }
                      helperText={
                        formik.touched.password_confirmation &&
                        formik.errors.password_confirmation
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-between",
                  }}
                  item
                  xs={12}
                >
                  <Box
                    sx={{
                      mr: { md: 1 },
                      my: { xs: 1, md: 0 },
                      width: { xs: "100%", md: "60%" },
                    }}
                  >
                    <Select
                      // IconComponent={() => <Box component="img" src={dropdown} />}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      fullWidth
                      placeholder="Select city"
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "18px",
                        ".MuiInputBase-input": {
                          fontFamily: "poppins",
                          py: "12px !important",
                          color: "white",
                          textAlign: "center",
                        },
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.2)",
                        bgcolor: "rgba(61, 197, 255, 0.26)",
                        borderRadius: "8px !important",
                        border: "3px solid rgba(58, 224, 253, 1)",
                        color: "white",
                        textAlign: "center",
                        ".MuiMenuItem-root": {
                          fontFamily: "poppins",
                          fontSize: "18px",
                        },
                        ".MuiSvgIcon-root": {
                          color: "rgba(0,200,1,1)",
                          fontSize: "50px",
                        },
                      }}
                      name="city_id"
                      value={formik.values.city_id}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.city_id && Boolean(formik.errors.city_id)
                      }
                      helperText={
                        formik.touched.city_id && formik.errors.city_id
                      }
                    >
                      {cities?.map((city, index) => {
                        return (
                          <MenuItem key={index} value={city?.id}>
                            {city?.name}
                          </MenuItem>
                        );
                      })}
                      {/* <MenuItem value={2}>{`Pakistan`}</MenuItem> */}
                    </Select>
                  </Box>
                  <Box sx={{ width: { xs: "100%", md: "40%" } }}>
                    <TextField
                      placeholder="Zip Code"
                      sx={{
                        width: "100%",
                        fontFamily: "poppins",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        ".MuiInputBase-input": {
                          fontSize: "18px",
                          fontFamily: "poppins",
                          padding: 0,
                          color: "white",
                          textAlign: "center",
                          bgcolor: "rgba(61, 197, 255, 0.26)",

                          "::placeholder": {
                            fontFamily: "poppins",
                            fontSize: "18px",
                            color: "#fff !important",
                            textAlign: "center",
                          },
                        },
                        ".MuiOutlinedInput-root": {
                          py: "11.5px !important",
                          borderRadius: "8px !important",
                          textAlign: "center",
                          boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.2)",
                          border: "3px solid rgba(58, 224, 253, 1)",
                        },
                        borderRadius: "8px !important",
                        color: "white",
                        textAlign: "center",
                      }}
                      textAlign="center"
                      name="zip_code"
                      value={formik.values.zip_code}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.zip_code &&
                        Boolean(formik.errors.zip_code)
                      }
                      helperText={
                        formik.touched.zip_code && formik.errors.zip_code
                      }
                    />
                  </Box>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "space-between",
                  }}
                  item
                  xs={12}
                >
                  {/* <Box sx={{ mr: { md: 1 }, width: { xs: "100%", md: "40%" } }}>
                    <Select
                      // IconComponent={() => <Box component="img" src={dropdown} />}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      size="small"
                      fullWidth
                      placeholder="Select city_id"
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "18px",
                        ".MuiInputBase-input": {
                          fontFamily: "poppins",
                          py: "12px !important",
                          color: "white",
                          textAlign: "center",
                        },
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.2)",
                        bgcolor: "rgba(61, 197, 255, 0.26)",
                        borderRadius: "8px !important",
                        border: "3px solid rgba(58, 224, 253, 1)",
                        color: "white",
                        textAlign: "center",
                        ".MuiMenuItem-root": {
                          fontFamily: "poppins",
                          fontSize: "18px",
                        },
                        ".MuiSvgIcon-root": {
                          color: "rgba(0,200,1,1)",
                          fontSize: "50px",
                        },
                      }}
                      defaultValue="0"
                      name="countryCode"
                      value={formik.values.countryCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.countryCode &&
                        Boolean(formik.errors.countryCode)
                      }
                      helperText={
                        formik.touched.countryCode && formik.errors.countryCode
                      }
                    >
                      {codes?.map((code, index) => {
                        return <MenuItem value={index}>{`+${code}`} </MenuItem>;
                      })}
                    </Select>
                  </Box> */}
                  <Box sx={{ width: { xs: "100%", md: "100%" } }}>
                    <TextField
                      placeholder="4558989-445"
                      sx={{
                        my: { xs: 1, md: 0 },
                        fontFamily: "poppins",
                        ".MuiOutlinedInput-notchedOutline": { border: 0 },
                        ".MuiInputBase-input": {
                          fontSize: "18px",
                          fontFamily: "poppins",
                          padding: 0,
                          color: "white",
                          textAlign: "center",
                          bgcolor: "rgba(61, 197, 255, 0.26)",

                          "::placeholder": {
                            fontFamily: "poppins",
                            fontSize: "18px",
                            color: "#fff !important",
                            textAlign: "center",
                          },
                        },
                        ".MuiOutlinedInput-root": {
                          py: "11.5px !important",
                          borderRadius: "8px !important",
                          textAlign: "center",
                          boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.2)",
                          border: "3px solid rgba(58, 224, 253, 1)",
                        },
                        borderRadius: "8px !important",
                        color: "white",
                        textAlign: "center",
                        width: "100%",
                      }}
                      textAlign="center"
                      name="phone_number"
                      value={formik.values.phone_number}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.phone_number &&
                        Boolean(formik.errors.phone_number)
                      }
                      helperText={
                        formik.touched.phone_number &&
                        formik.errors.phone_number
                      }
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={formik.handleSubmit}
                    type="submit"
                    variant="contained"
                    sx={{
                      fontFamily: "poppins",
                      boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.2)",
                      px: 3,
                      borderRadius: "10px",
                      textTransform: "none",
                      height: "58px",
                      backgroundColor: "#58E6FF",
                      color: "white",
                      width: { xs: "100%" },
                      "&:hover": {
                        transform: "scale(1.01)",
                        bgcolor: "#FFB501",
                      },
                      fontSize: 20,
                    }}
                  >
                    SignUp
                  </Button>
                </Grid>
                <Grid xs={12}>
                  <Typography
                    sx={{
                      width: "100%",
                      fontFamily: "poppins",
                      color: "#fff",
                      py: 2,
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Or SignUp with your social Accounts
                  </Typography>
                </Grid>
                <Grid
                  sx={{ display: "flex", justifyContent: "center" }}
                  xs={12}
                >
                  <Box
                    component="img"
                    src={google}
                    sx={{
                      height: "40px",
                      width: "40px",
                      mx: 1,
                      cursor: "pointer",
                    }}
                  ></Box>
                  <Box
                    component="img"
                    src={fb}
                    sx={{
                      height: "40px",
                      width: "40px",
                      mx: 1,
                      cursor: "pointer",
                    }}
                  ></Box>
                </Grid>
                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  xs={12}
                >
                  <Typography
                    sx={{
                      // width: "100%",
                      fontFamily: "poppins",
                      color: "#fff",
                      py: 2,
                      textAlign: "center",
                      fontSize: "16px",
                    }}
                  >
                    Already have an account?
                  </Typography>
                  <Typography
                    sx={{
                      color: "#005371",
                      cursor: "pointer",
                      // width: "100%",
                      fontFamily: "poppins",
                      py: 2,
                      textAlign: "center",
                      fontSize: "16px",
                      ml: 1,
                    }}
                    onClick={() => Navigate("/auth/signin")}
                  >
                    SignIn
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default SignUp;
