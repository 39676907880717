import React, { useState, useEffect } from "react";
import Layout from "./../components/Admin/Layout/Layout";
import AdminRoutes from "../routes/Admin/AdminRoutes.js";
import { useNavigate } from "react-router-dom";

export default function Buyer() {
  const [user, setUser] = useState(false);
  let navigate = useNavigate();
  useEffect(() => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log("user", user);
    if (user?.role) {
      setUser(true);
    } else {
      setUser(false);
    }
  }, [user]);
  return (
    <div>
      {user ? (
        <Layout>
          <AdminRoutes />
        </Layout>
      ) : (
        navigate("/auth/signin")
      )}
    </div>
  );
}
