import React from "react";
import {
  Grid,
  Box,
  TextField,
  IconButton,
  InputAdornment,
  FormControl,
  Input,
  Select,
  MenuItem,
  Button,
  Typography,
} from "@mui/material";
import bgImg from "../../assets/images/HeaderBg.png";
import blurbox from "../../assets/images/forgetpassword.png";
import welcomeImg from "../../assets/images/welcome.png";
import fb from "../../assets/images/fb.png";
import google from "../../assets/images/google.png";
import buyerBtn from "../../assets/images/buyerBtn.png";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useNavigate } from "react-router-dom";
import CodeVerification from "./ForgetPassword/CodeVerification";
function ForgetPassword() {
  let Navigate = useNavigate();
  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Box
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        zIndex: 0,
        height: "100vh",
        position: "relative",
        backgroundImage: `url(${bgImg})`,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          position: "absolute",

          //   top: 0,
          bgcolor: "rgba(61,197,255,0.3)",
        }}
      >
        <Grid container>
          <Grid
            sx={{
              height: "100vh",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
            item
            xs={12}
            md={6}
          >
            <Box
              sx={{
                width: { xs: "300px", sm: "450px" },
                minHeight: "240px",
                backgroundRepeat: "no-repeat",
                backgroundSize: { xs: "contain", md: "cover" },
                backgroundImage: `url(${blurbox})`,
                position: "relative",
                display: { md: "block" },
              }}
            ></Box>
          </Grid>
          <Grid
            item
            sx={{
              height: { xs: "100%", md: "100vh" },
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
            md={6}
          >
            <Box
              component="form"
              sx={{
                position: "relative",
                bgcolor: "rgba(61,197,255,0.88)",
                width: { xs: "100%", md: "430px" },
                height: { xs: "100%", md: "75vh" },
                borderRadius: { xs: 0, md: "22px" },
                zIndex: 5,
              }}
            >
              <Box
                sx={{
                  width: "150px",
                  height: "50px",
                  position: "absolute",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundImage: `url(${buyerBtn})`,
                  top: -23,
                  left: -40,
                  zIndex: 10,
                }}
              ></Box>
              <Grid sx={{ pt: 8, px: { xs: 4, md: 8 } }} container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    sx={{
                      width: "100%",
                      fontFamily: "Poppins",
                      ".MuiInputBase-input": {
                        fontSize: "18px",
                        fontFamily: "Poppins",
                        padding: 0,
                        pb: 1,
                        pl: 2,
                        color: "white",
                        textAlign: "left",
                        "::placeholder": {
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          color: "rgba(255,255,255,1) !important",

                          textAlign: "left",
                        },
                      },
                      ".MuiInput-underline": {
                        "::before": {
                          borderBottom: "2px solid #fff !important",
                        },
                      },
                      ".MuiOutlinedInput-root ": {
                        py: "12px !important",
                        // px: "72px !important",
                        borderRadius: "8px !important",
                        textAlign: "left",
                        bgcolor: "#26B3F0",
                        boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.4)",
                      },
                      borderRadius: "5px !important",
                      color: "white",
                      textAlign: "center",
                    }}
                    placeholder="Enter Your Email"
                    variant="standard"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    sx={{
                      fontFamily: "poppins",
                      boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.2)",
                      px: 3,
                      borderRadius: "10px",
                      textTransform: "none",
                      height: "58px",
                      backgroundColor: "#58E6FF",
                      color: "white",
                      width: { xs: "100%" },
                      "&:hover": {
                        transform: "scale(1.01)",
                        bgcolor: "#FFB501",
                      },
                      fontSize: 20,
                    }}
                  >
                    Send Code
                  </Button>
                </Grid>
                <Grid sx={{ mt: 2 }} item xs={12}>
                  <CodeVerification />
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      //   py: 2,
                      textAlign: "center",
                      fontSize: "14px",
                      color: "#fff",
                    }}
                  >
                    Send Code Again
                  </Typography>
                </Grid>

                <Grid
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  xs={12}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      cursor: "pointer",
                      // width: "100%",
                      fontFamily: "poppins",
                      py: 2,
                      textAlign: "center",
                      fontSize: "16px",
                      ml: 1,
                    }}
                    onClick={() => Navigate("/auth/signin")}
                  >
                    Back
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default ForgetPassword;
