import axios from "../axiosConfig";

class Routes {
  wonBids() {
    return axios.get("/admin/won/bids");
  }
  setBidWinner(id) {
    return axios.put(`/admin/bid/status/${id}`);
  }
}

export default new Routes();
