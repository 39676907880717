import React from "react";
import { Box, Button, Paper, Typography, Grid } from "@mui/material";
import userImg from "../../../assets/images/userImg.png";
import master from "../../../assets/images/master.png";
import visa from "../../../assets/images/visa.png";
import { BuyerAPI } from "../../../api";
import { toast } from "react-toastify";
const { REACT_APP_HOST } = process.env;

function BuyerCard({ user, setChange }) {
  function ChangeStatus(id) {
    BuyerAPI.changeBuyerStatus(id).then((res) => {
      if (res?.data?.status == 200) {
        toast.success("Buyer Status Changed");
        setChange(true);
      } else {
        toast.error(res?.data?.message);
      }
    });
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Paper
        sx={{
          width: { xs: "190px", md: "200px", lg: "220px" },
          height: { xs: "240px", md: "250px", lg: "250px" },
          bgcolor: "#53CBFF",
          border: "1px solid #26B3F0",
          mx: 4,
          pt: 2,
          borderRadius: "14px",
        }}
        elevation={8}
      >
        <Grid container>
          <Grid item className={"centerRow"} xs={12}>
            <Box
              sx={{ width: "50px", height: "50px", mt: 1 }}
              component="img"
              src={
                user?.image?.url
                  ? `${REACT_APP_HOST}${user?.image?.url}`
                  : userImg
              }
            />
          </Grid>
          <Grid
            item
            className={"centerRow"}
            sx={{ color: "red", textAlign: "center", pt: 0.5 }}
            xs={12}
          >
            <Typography fontFamily="Raleway, sans-serif" color="#005371">
              {`${user?.username}`}
            </Typography>
          </Grid>
          <Grid
            item
            className={"centerRow"}
            sx={{ color: "white", textAlign: "center", pt: 0.5 }}
            xs={12}
          >
            <Typography fontFamily="Raleway, sans-serif">
              {user?.email}
            </Typography>
          </Grid>
          <Grid
            item
            className={"centerRow"}
            sx={{ color: "white", textAlign: "center", pt: 0.5 }}
            xs={12}
          >
            <Typography fontFamily="Raleway, sans-serif">
              Bought Products: {user?.transactions?.length}
            </Typography>
          </Grid>
          <Grid
            item
            className={"centerRow"}
            sx={{ color: "white", textAlign: "center", pt: 0.5 }}
            xs={12}
          >
            <Typography fontFamily="Raleway, sans-serif">
              No. of transactions: {user?.transactions?.length}
            </Typography>
          </Grid>
          <Grid item className={"centerRow"} xs={12}>
            <Box sx={{ display: "flex", justifyContent: "space-around" }}>
              <Box
                sx={{ width: "75px", height: "40px", mt: 1 }}
                component="img"
                src={master}
              />
              <Box
                sx={{ width: "75px", height: "40px", mt: 1 }}
                component="img"
                src={visa}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "space-around" },
          alignItems: "center",
          my: 1,
          width: { xs: "190px", md: "200px", lg: "220px" },
          //   bgcolor: "red",
        }}
      >
        {/* <Button
          sx={{
            width: { xs: "100%", sm: "90px" },
            bgcolor: "rgba(38,179, 240,1)",
            borderRadius: "8px",
            mb: { xs: 1, sm: 0 },
            fontFamily: "poppins !important",
          }}
          variant="contained"
          disabled={true}
        >
          Active
        </Button> */}
        <Button
          sx={{
            width: { xs: "100%", sm: "90px" },
            bgcolor: "rgba(38,179, 240,1)",
            borderRadius: "8px",
            fontFamily: "poppins !important",
          }}
          variant="contained"
          onClick={() => ChangeStatus(user?.id)}
        >
          {user?.status == 1 ? "Disable" : "Active"}
        </Button>
      </Box>
    </Box>
  );
}

export default BuyerCard;
