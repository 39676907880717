import React, { useEffect, useState } from "react";
import BidsCard from "./BidsCard";
import Carousel from "../../../utils/Carousel";
import cat1 from "../../../assets/images/cat1.png";
import SouthIcon from "@mui/icons-material/South";
import userImg from "../../../assets/images/userImgB.png";
import { Box, Button, Paper, Typography, Grid } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Loader from "../../../utils/Loader";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import { BidsAPI } from "../../../api";
import { toast } from "react-toastify";

const { REACT_APP_HOST } = process.env;

function CurrentBids({ unWonBids, setBidWon }) {
  const [selectedCard, setSelectedCard] = useState(0);
  const [selectedproduct, setSelectedProduct] = useState({});
  const theme = createTheme({
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: "10px",
        },
        "*::-webkit-scrollbar-track": {
          background: "#E4EFEF",
        },
        "*::-webkit-scrollbar-thumb": {
          background: "#1D388F61",
          borderRadius: "2px",
        },
      },
    },
  });
  const WonBids = [
    {
      id: 1,
      title: "item #1",
      image: cat1,
      count: "22",
      bidNo: "22",
      amount: "10000",
      price: "10000",
      year: "2002",
    },
    {
      id: 2,
      title: "item #2",
      image: cat1,
      count: "4",
      price: "20000",
      year: "2016",
      bidNo: "22",
      amount: "10000",
    },
    {
      id: 3,
      title: "item #3",
      image: cat1,
      count: "65",
      price: "700",
      year: "2020",
      bidNo: "22",
      amount: "10000",
    },
    {
      id: 4,
      title: "item #4",
      image: cat1,
      count: "70",
      price: "9000",
      year: "2002",
      bidNo: "22",
      amount: "10000",
    },
    {
      id: 5,
      title: "item #5",
      image: cat1,
      count: "1",
      price: "3000",
      year: "2009",
      bidNo: "22",
      amount: "10000",
    },
  ];

  useEffect(() => {
    let newselectedproduct = unWonBids[selectedCard];
    console.log("newselectedproduct", newselectedproduct);
    setSelectedProduct(newselectedproduct);
  }, [selectedCard, unWonBids]);

  function BidWinner(bid_id) {
    BidsAPI.setBidWinner(bid_id).then((res) => {
      if (res.data.status == 200) {
        toast.success("Bid Won");
        setBidWon(true);
      } else {
        toast.error(res?.data?.status);
      }
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <React.Suspense fallback={<Loader />}>
        {unWonBids?.length > 0 ? (
          <Box>
            <Box>
              <Carousel>
                {[...new Set(unWonBids)]?.map((item, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <BidsCard
                      item={item}
                      index={index}
                      setSelectedCard={setSelectedCard}
                    ></BidsCard>
                    <Box
                      sx={{ display: selectedCard == index ? "block" : "none" }}
                      className="centerRow"
                    >
                      <SouthIcon color="error" />
                    </Box>
                  </Box>
                ))}
              </Carousel>
            </Box>
            <Box
              maxHeight="60vh"
              minHeight="100%"
              sx={{
                overflowY: "scroll",
                "&::-webkit-scrollbar": {
                  width: "10px",
                },
                "&::-webkit-scrollbar-track": {
                  boxShadow: "inset 0 0 6px #B9EDFF",
                  webkitBoxShadow: "inset 0 0 6px #26B3F0",
                  border: "1px solid #26B3F0",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#26B3F0",
                  outline: "1px solid slategrey",
                },
              }}
            >
              {unWonBids[selectedCard]?.bids?.map((bid, index) => {
                return (
                  <Grid
                    container
                    sx={{ px: { md: 8 } }}
                    position="relative"
                    className="centerRow"
                  >
                    <Grid item xs={12} md={2}>
                      <Box
                        sx={{ width: "50px", height: "50px", mt: 1 }}
                        component="img"
                        src={
                          bid?.user?.image?.url
                            ? `${REACT_APP_HOST}${bid?.user?.image?.url}`
                            : userImg
                        }
                      ></Box>
                      <Typography
                        sx={{ fontSize: { xs: 10, sm: 12, md: 14, lg: 18 } }}
                      >
                        {bid?.user?.username}
                      </Typography>
                    </Grid>
                    <Grid
                      bgcolor="rgba(38,179, 240,1)"
                      display="flex"
                      justifyContent="space-around"
                      alignItems="center"
                      position="relative"
                      item
                      xs={12}
                      md={10}
                      sx={{
                        my: { xs: 2, md: 2 },
                        py: { xs: 1, sm: 2 },
                        borderRadius: { xs: "4px", md: "14px" },
                      }}
                    >
                      <Typography
                        color={"white"}
                        sx={{ fontSize: { xs: 10, sm: 12, md: 14, lg: 18 } }}
                        fontFamily="Raleway, sans-serif"
                      >
                        {bid?.user?.username}
                      </Typography>
                      <Typography
                        color={"white"}
                        sx={{ fontSize: { xs: 10, sm: 12, md: 14, lg: 18 } }}
                        fontFamily="Raleway, sans-serif"
                      >
                        Bid Number: {bid?.id}
                      </Typography>
                      <Typography
                        color={"white"}
                        sx={{ fontSize: { xs: 10, sm: 12, md: 14, lg: 18 } }}
                        fontFamily="Raleway, sans-serif"
                      >
                        Bid Amount: {bid?.amount}
                      </Typography>
                      {selectedproduct?.end_time ? null : (
                        <EmojiEventsIcon
                          onClick={() => BidWinner(bid?.id)}
                          sx={{ color: "white", cursor: "pointer" }}
                        />
                      )}
                    </Grid>
                  </Grid>
                );
              })}
            </Box>
          </Box>
        ) : (
          <Typography
            sx={{
              height: "100px",
              width: "100%",
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            No Product Found
          </Typography>
        )}
      </React.Suspense>
    </ThemeProvider>
  );
}

export default CurrentBids;
