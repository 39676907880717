import React, { useState } from "react";
import { Box, Grid, Typography, Paper, Button } from "@mui/material";
import InListCard from "./InListCard";
import cat1 from "../../../assets/images/img8.jpg";
import {  ProductAPI } from "../../../api";
import { toast } from "react-toastify";
const soldProducts = [
  {
    id: 1,
    title: "item #1",
    image: cat1,
    count: "22",
    bidNo: "22",
    amount: "10000",
    price: "10000",
    year: "2002",
  },
  {
    id: 2,
    title: "item #2",
    image: cat1,
    count: "4",
    price: "20000",
    year: "2016",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 3,
    title: "item #3",
    image: cat1,
    count: "65",
    price: "700",
    year: "2020",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 4,
    title: "item #4",
    image: cat1,
    count: "70",
    price: "9000",
    year: "2002",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 5,
    title: "item #5",
    image: cat1,
    count: "1",
    price: "3000",
    year: "2009",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 1,
    title: "item #1",
    image: cat1,
    count: "22",
    bidNo: "22",
    amount: "10000",
    price: "10000",
    year: "2002",
  },
  {
    id: 2,
    title: "item #2",
    image: cat1,
    count: "4",
    price: "20000",
    year: "2016",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 3,
    title: "item #3",
    image: cat1,
    count: "65",
    price: "700",
    year: "2020",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 4,
    title: "item #4",
    image: cat1,
    count: "70",
    price: "9000",
    year: "2002",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 5,
    title: "item #5",
    image: cat1,
    count: "1",
    price: "3000",
    year: "2009",
    bidNo: "22",
    amount: "10000",
  },
];

function InList({ unsold, cat, setisChanged }) {
  const [selected, setSelected] = useState(0);

  const [checked, setChecked] = useState(0);
  const [categories, setCategories] = useState([]);
  const [productsByCat, setProductsByCat] = useState([]);
  const handleChange = (event) => {
    console.log("CHECKED_LOG", event);
    setChecked(event);
  };

  function deleteProduct() {
    ProductAPI.deleteProduct(checked).then((res) => {
      setisChanged(true);
      toast.success(res.data.message);
      // CategoryAPI.allCategories().then((res) => {
      //   setCategories(res?.data?.data);
      //   let newArr = res?.data.data?.filter((item) => item.id == selected);
      //   setProductsByCat(newArr);
      //   // setisLoading(false);
      // });
    });
  }

  return (
    <Box>
      <Grid
        maxHeight="100vh"
        minHeight="100%"
        container
        sx={{
          px: 8,
          overflowY: "scroll",
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px #B9EDFF",
            webkitBoxShadow: "inset 0 0 6px #26B3F0",
            border: "1px solid #26B3F0",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#26B3F0",
            outline: "1px solid slategrey",
          },
        }}
      >
        {unsold?.map((product, index) => {
          return (
            <Grid
              className="centerRow"
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              xl={2.3}
            >
              <InListCard
                cat={cat}
                index={index}
                checked={checked}
                handleChange={handleChange}
                setChecked={setChecked}
                item={product}
              />
            </Grid>
          );
        })}
      </Grid>
      <Box width="100%" className={"centerRow"}>
        <Button
          sx={{
            width: { xs: "200px", md: "250px" },
            fontSize: { xs: 12, md: 18 },
            bgcolor: "#EC1C24 !important",
            my: { xs: 5, md: 4 },
            py: 1.5,
            fontFamily: "Poppins, sans-serif",
            borderRadius: "10px",
          }}
          variant="contained"
          onClick={() => deleteProduct()}
        >
          Remove From List
        </Button>
      </Box>
    </Box>
  );
}

export default InList;
