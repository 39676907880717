import React, { useState, useEffect } from "react";
import { Typography } from "@mui/material";
import dayjs from "dayjs";
function Timer({ end_time }) {
  const [time, setTime] = useState(handleTime(end_time));
  const [endTime, setEndTime] = useState("00:00");

  useEffect(() => {
    let interval = setInterval(() => {
      let calculation = handleTime(end_time);
      setTime(calculation);
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function handleTime(end_time) {
    let date1 = dayjs();
    let sec = date1.diff(dayjs(end_time), "second");
    let hr = date1.diff(dayjs(end_time), "hour");
    // console.log("newHours", hr * 60 * 60);
    let hr2sec = hr * 60 * 60;
    let ignoredSeconds = sec - hr2sec;
    let Minutes = ignoredSeconds / 60;
    let obj = {
      hours: hr,
      minutes: Math.round(Minutes),
    };
    return obj;
  }
  return (
    <Typography
      sx={{
        width: { xs: "100%" },
        fontSize: { xs: 6, md: 6, lg: 12 },
        bgcolor: "rgba(38,179, 240,1) !important",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      //   fontFamily="Raleway, sans-serif"
    >
      {time?.minutes < 0
        ? `${`${Math.abs(time?.hours)} hours `}
                ${Math.abs(time?.minutes)} minutes`
        : "No Time Limit"}
    </Typography>
  );
}

export default Timer;
