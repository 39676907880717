import React, { useState } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import userImg from "../../../assets/images/userImgB.png";
const { REACT_APP_HOST } = process.env;

function BidsCard({ item, index, setSelectedCard }) {
  return (
    <div>
      <Box className={"centerColumn"} sx={{ px: 2, py: { xs: 4, md: 4 } }}>
        <Paper
          elevation={12}
          sx={{
            // cursor: "pointer",
            borderRadius: "15%",
            width: { xs: "120px", md: "170px" },
            height: { xs: "120px", md: "170px  " },
            // bgcolor: "rgba(255,255, 255,0.4) !important",
            backgroundImage: `url(${REACT_APP_HOST}${item?.images[0]?.url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
            position: "relative",
            border: "3px solid #B9EDFF",
            p: "5%",
          }}
        >
          <Box
            sx={{
              width: { xs: "40px", md: "50px" },
              height: { xs: "40px", md: "50px" },
              borderRadius: "50%",
              bgcolor: "red",
              position: "absolute",
              bgcolor: "rgba(38,179, 240,1) !important",
              transformOrigin: "90% 40% !important",
              top: -20,
              left: -20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              className={"centerColumn"}
              sx={{ color: "white", fontSize: { xs: 10, md: 13 } }}
            >
              {item?.bids?.length}
              <Typography sx={{ color: "white", fontSize: { xs: 10, md: 13 } }}>
                Bids
              </Typography>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "flex-end", md: "space-around" },
              alignItems: { xs: "center", md: "flex-end" },
              // bgcolor: "red",
              height: "100%",
            }}
          >
            <Button
              sx={{
                cursor: "pointer",
                width: { xs: "100%", md: "90%" },
                fontSize: { xs: 8, md: 10, lg: 12 },
                bgcolor: "rgba(38,179, 240,1)  !important",
                mb: { xs: 1, md: 0 },
                fontFamily: "Poppins, sans-serif",
              }}
              variant="contained"
              onClick={() => {
                setSelectedCard(index);
              }}
            >
              View Bids
            </Button>
          </Box>
        </Paper>
        <Typography
          // variant="h5"
          // align="center"
          color="#024861"
          fontFamily="Raleway, sans-serif"
          fontWeight="bold"
          sx={{ fontSize: { xs: 18, md: 16, xl: 22 } }}
        >
          Price: $ {item?.price}
        </Typography>
        <Typography
          // variant="h5"
          // align="center"
          color="#024861"
          fontFamily="Raleway, sans-serif"
          fontWeight="bold"
          sx={{ fontSize: { xs: 18, md: 16, xl: 22 } }}
        >
          Year: {item?.year}
        </Typography>
        {/* <Typography
          // variant="h5"
          // align="center"
          color="#024861"
          fontFamily="Raleway, sans-serif"
          fontWeight="bold"
          sx={{ fontSize: { xs: 18, md: 16, xl: 22 } }}
        >
          Your Bid No: {item?.bidNo}
        </Typography> */}
        {/* <Box className={"centerColumn"} sx={{ py: 1 }}>
          <Box
            sx={{ width: "50px", height: "50px", mt: 1 }}
            component="img"
            src={userImg}
          ></Box>
          <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14, lg: 18 } }}>
            Seller Name
          </Typography>
        </Box> */}
      </Box>
    </div>
  );
}

export default BidsCard;
