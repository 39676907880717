import React from "react";
import Carousel, { consts } from "react-elastic-carousel";
import { Button, Box } from "@mui/material";
import rightArrow from "../assets/icons/rightArrow.png";
import leftArrow from "../assets/icons/leftArrow.png";
import Loader from "./Loader";

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 450, itemsToShow: 2 },
  { width: 630, itemsToShow: 3 },
  { width: 800, itemsToShow: 4 },
  { width: 1200, itemsToShow: 4 },
];
function CarouselComp(props) {
  function myArrow({ type, onClick, isEdge }) {
    const pointer =
      type === consts.PREV ? (
        <Box component="img" src={leftArrow} />
      ) : (
        <Box component="img" src={rightArrow} />
      );
    return (
      <Box height="100%" sx={{ display: "flex", alignItems: "center" }}>
        <Button
          // sx={{ height: "26px", width: "20px", borderRadius: "50%" }}
          onClick={onClick}
          disabled={isEdge}
        >
          {pointer}
        </Button>
      </Box>
    );
  }
  return (
    <Box>
      <Carousel
        itemsToScroll={4}
        renderArrow={myArrow}
        breakPoints={breakPoints}
      >
        {props.children}
      </Carousel>
    </Box>
  );
}

export default CarouselComp;
