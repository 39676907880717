import React from "react";
import { Box, Typography } from "@mui/material";
function Subheading(props) {
  return (
    <Box>
      <Typography
        fontFamily="Poppins, sans-serif"
        sx={{
          color: "#024861",
          //   textShadow: "0px 0.5px 5px rgba(0,0,0,0.5) ",
          fontSize: { xs: 36, sm: 32, md: 22, lg: 52 },
          pt: { xs: 1, md: 2, lg: 7, xl: 9 },
          pb: { xs: 3, md: 3, lg: 7, xl: 9 },
          fontWeight: 900,
          textAlign: "left",
        }}
      >
        {props.children}
      </Typography>
    </Box>
  );
}

export default Subheading;
