import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Select,
  MenuItem,
  TextField,
  Checkbox,
} from "@mui/material";
import Residence from "../../../assets/images/Residence.png";
import Commercial from "../../../assets/images/Commercial.png";
import car from "../../../assets/images/car.png";
import shop from "../../../assets/images/shop.png";
import genral from "../../../assets/images/genral.png";
import mechine from "../../../assets/images/mechine.png";
import Subheading from "../../../utils/HeadingAdmin";
import Carousel from "../../../utils/Carousel";
import PurchaseCard from "./ProductCard";
import cat1 from "../../../assets/images/cat1.png";
import { CategoryAPI, ProductAPI } from "../../../api";
import Loader from "../../../utils/Loader";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { toast } from "react-toastify";
const { REACT_APP_HOST } = process.env;

const WonBids = [
  {
    id: 1,
    title: "item #1",
    image: cat1,
    count: "22",
    bidNo: "22",
    amount: "10000",
    price: "10000",
    year: "2002",
  },
  {
    id: 2,
    title: "item #2",
    image: cat1,
    count: "4",
    price: "20000",
    year: "2016",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 3,
    title: "item #3",
    image: cat1,
    count: "65",
    price: "700",
    year: "2020",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 4,
    title: "item #4",
    image: cat1,
    count: "70",
    price: "9000",
    year: "2002",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 5,
    title: "item #5",
    image: cat1,
    count: "1",
    price: "3000",
    year: "2009",
    bidNo: "22",
    amount: "10000",
  },
];
let Cats = [
  {
    image: Commercial,
    title: "Commercial",
    count: "99+",
  },
  {
    image: Residence,
    title: "Residence",
    count: "233",
  },

  {
    image: car,
    title: "Cars",
    count: "12",
  },
  {
    image: shop,
    title: "Shops",
    count: "99",
  },
  {
    image: genral,
    title: "Genral Items",
    count: "233",
  },
  {
    image: mechine,
    title: "Mechinery",
    count: "1",
  },
];

function AuctionSetting() {
  const [aTozValue, setaTozValue] = useState(1);
  const [selected, setSelected] = useState(1);
  const [categories, setCategories] = useState([]);
  const [productsByCat, setProductsByCat] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [isUpdated, setisUpdated] = useState(false);
  const [checked, setChecked] = useState(null);
  const handleChange = (event) => {
    // console.log("CHECKED_LOG", event);
    setChecked(event);
  };

  let date = new Date();
  const [value, setValue] = React.useState(dayjs(date));
  const [dateTime, setDateTime] = React.useState(
    dayjs(value).format("YYYY-MM-DD HH:mm:ss")
  );

  const handleTimeChange = (newValue) => {
    // console.log("date", dayjs(newValue).format("YYYY-MM-DD HH:mm:ss"));
    setDateTime(dayjs(newValue).format("YYYY-MM-DD HH:mm:ss"));
    setValue(newValue);
  };
  useEffect(() => {
    CategoryAPI.allCategories().then((res) => {
      setCategories(res?.data?.data);
      let newArr = res?.data.data?.filter((item) => item.id == selected);
      setProductsByCat(newArr);
      setisLoading(false);
    });
  }, [isUpdated]);

  useEffect(() => {
    let newArr = categories?.filter((item) => item.id == selected);
    setProductsByCat(newArr);
    setisUpdated(false);
  }, [selected, isUpdated]);

  function handleSubmit() {
    ProductAPI.setting(checked, { end_time: dateTime, _method: "put" }).then(
      (res) => {
        if (res?.data?.status == 200) {
          setisUpdated(true);
          toast.success("Setting Updated");
        } else {
          toast.error(res?.data?.message);
        }
      }
    );
  }
  return (
    <Box height="100%" pb={5} width="100%">
      <Box className={"centerRow"} sx={{ py: { md: 1 }, pb: { xs: 2, md: 0 } }}>
        <Subheading>Settings</Subheading>
      </Box>
      <Box className={"centerRow"} spacing={2}>
        <Grid container sx={{ width: "70%" }}>
          <Grid item xs={12} md={5}>
            <Typography
              sx={{
                color: "#024861",
                textShadow: "0px 0.3px 2px rgba(0,0,0,0.5) ",
                fontSize: { xs: 22, md: 22, lg: 24 },
                pt: { xs: 1, md: 1 },
                pb: { xs: 1, md: 1 },
              }}
            >
              Fee For Auction (%)
            </Typography>
            <TextField
              readonly
              disabled
              sx={{
                ".MuiInputBase-input": {
                  //   px: "20px !important",
                  color: "white",
                  padding: 0,
                  textAlign: "center",
                  "::placeholder": {
                    color: "white !important",
                    textAlign: "center",
                  },
                },
                ".MuiOutlinedInput-root": {
                  py: "8px !important",
                  px: "48px !important",
                  borderRadius: "8px !important",
                  textAlign: "center",
                },
                ".Mui-disabled": {
                  color: "white",
                },
                borderRadius: "8px !important",
                // borderRadius: "14px",
                boxShadow: " 0px 4px 6px 0px rgba(0,0,0,0.3)",
                bgcolor: "rgb(29, 187, 255 , 9.5)",
                color: "white",
                textAlign: "center",
              }}
              defaultValue="1"
              textAlign="center"
              placeholder="1%"
            />
          </Grid>
          <Grid item xs={12} md={5}>
            <Typography
              sx={{
                color: "#024861",
                textShadow: "0px 0.3px 2px rgba(0,0,0,0.5) ",
                fontSize: { xs: 22, md: 22, lg: 24 },
                pt: { xs: 1, md: 1 },
                pb: { xs: 1, md: 1 },
              }}
            >
              End Time For Auction
            </Typography>
            {/* <TextField
              id="time"
              type="time"
              defaultValue="07:30"
              InputLabelProps={{
                shrink: true,
              }}
              ampm={false}
              inputProps={{
                step: 300, // 5 min
                ampm: false,
              }}
              sx={{
                ".MuiInputBase-input": {
                  padding: 0,
                  //   py: "4px !important",
                  color: "white",
                  //   borderRadius: "24px !important",
                  //   px: "48px !important",
                },
                ".MuiOutlinedInput-root": {
                  px: {
                    xs: "86px",
                    sm: "44px !important",
                    md: "85px !important",
                  },
                  py: "8px !important",
                  borderRadius: "8px !important",
                },
                borderRadius: "8px !important",
                boxShadow: " 0px 4px 6px 0px rgba(0,0,0,0.3)",
                bgcolor: "rgb(29, 187, 255 , 9.5)",
                color: "#fff !important",
              }}
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                value={value}
                ampm={false}
                onChange={handleTimeChange}
                renderInput={(params) => (
                  <TextField
                    sx={{
                      ".MuiInputBase-input": {
                        //   px: "20px !important",
                        color: "white",
                        padding: 0,
                        textAlign: "center",
                        "::placeholder": {
                          color: "white !important",
                          textAlign: "center",
                        },
                      },
                      ".MuiOutlinedInput-root": {
                        py: "8px !important",
                        px: "28px !important",
                        borderRadius: "8px !important",
                        textAlign: "center",
                      },
                      borderRadius: "8px !important",
                      // borderRadius: "14px",
                      boxShadow: " 0px 4px 6px 0px rgba(0,0,0,0.3)",
                      bgcolor: "rgb(29, 187, 255 , 9.5)",
                      color: "white",
                      textAlign: "center",
                    }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            item
            xs={12}
            md={2}
            sx={{ display: "flex", alignItems: "flex-end" }}
          >
            <Button
              sx={{
                width: { xs: "100%", md: "100%" },
                fontSize: { xs: 8, md: 14 },
                bgcolor: "rgba(38,179, 240,1)  !important",
                py: "7px",
                px: "12px",
                fontFamily: "Poppins, sans-serif",
              }}
              variant="contained"
              onClick={() =>
                checked != null
                  ? handleSubmit()
                  : toast.warning("Please select a product first")
              }
            >
              Update
            </Button>
          </Grid>
        </Grid>
      </Box>

      <Box className={"centerRow"} sx={{ py: { md: 1 }, pb: { xs: 2, md: 0 } }}>
        <Subheading>Current Auctions</Subheading>
      </Box>
      <Box className={"centerRow"} sx={{ pb: 4 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 26, md: 22, lg: 24 },
            color: "#024861",
            fontFamily: "poppins",
          }}
        >
          Select Category
        </Typography>
      </Box>
      <Loader active={isLoading} />

      <Grid container spacing={2} px={"5%"}>
        {categories?.map((cat, index) => {
          return (
            <Grid
              item
              key={cat?.name}
              xs={6}
              sm={4}
              md={4}
              lg={2}
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems="center"
            >
              <Paper
                elevation={3}
                sx={{
                  cursor: "pointer",
                  width: {
                    xs: "80px",
                    md: selected == cat?.id ? "140px" : "120px",
                  },
                  height: {
                    xs: "80px",
                    md: selected == cat?.id ? "140px" : "120px",
                  },
                  bgcolor:
                    selected == cat?.id
                      ? "rgba(61,197, 255,0.75) "
                      : "rgba(158,228, 253,0.4) !important",
                  borderRadius: "10px",
                  position: "relative",
                  p: "5%",
                }}
                onClick={() => {
                  // navigate("/auction");
                  setSelected(cat?.id);
                }}
              >
                <Box
                  sx={{
                    width: { xs: "40px", md: "50px" },
                    height: { xs: "40px", md: "50px" },
                    borderRadius: "50%",
                    bgcolor: "red",
                    position: "absolute",
                    bgcolor: "rgba(38,179, 240,1) !important",
                    transformOrigin: "90% 40% !important",
                    top: -20,
                    right: -20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "white" }}>
                    {cat?.products?.length}
                  </Typography>
                </Box>
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    height: "100%",
                    // width: { xs: "50%", sm: "60%", md: "100%" },
                    // heigth: { xs: "50%", sm: "60%", md: "100%" },
                    display: "block",
                    overflow: "hidden",
                    objectFit: "contain",
                  }}
                  src={`${REACT_APP_HOST}${cat?.image?.url}`}
                />
              </Paper>
              <Typography
                // variant="h5"
                // align="center"
                color="#024861"
                fontFamily="Raleway, sans-serif"
                fontWeight="bold"
                sx={{ fontSize: { xs: 18, md: 16, xl: 28 }, py: 2 }}
              >
                {cat?.name}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Box className={"centerRow"} sx={{ pt: { md: 1 }, pb: { xs: 2, md: 0 } }}>
        <Subheading>Auction Products</Subheading>
      </Box>
      {/* <Box className={"centerRow"}>
        <Box
          sx={{
            width: { xs: "60%", sm: "35%" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { md: "space-around" },
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button
            sx={{
              // width: { xs: "100%", md: "60%" },
              width: { xs: "100%", md: "120px" },
              height: "35px",
              borderRadius: { xs: "8px", sm: "10px" },
              fontSize: { xs: 12, md: 10, lg: 14 },
              bgcolor: "rgba(38,179, 240,1)  !important",
              mb: { xs: 1, md: 0 },
              fontFamily: "Poppins, sans-serif",
            }}
            variant="contained"
          >
            Edit
          </Button>
          <Button
            sx={{
              // width: { xs: "100%", md: "60%" },
              width: { xs: "100%", md: "120px" },

              height: "35px",
              borderRadius: { xs: "8px", sm: "10px" },
              fontSize: { xs: 12, md: 10, lg: 14 },
              bgcolor: "rgba(38,179, 240,1)  !important",
              mb: { xs: 1, md: 0 },
              fontFamily: "Poppins, sans-serif",
            }}
            variant="contained"
          >
            Delete
          </Button>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={aTozValue}
            // variant="outlined"
            size="small"
            fullWidth
            sx={{
              textAlign: "center",
              height: "35px",
              borderRadius: { xs: "8px", sm: "10px" },
              fontSize: { xs: 12, md: 10, lg: 14 },
              px: "14px",
              py: { xs: 0, sm: "1px" },
              width: { xs: "100%", md: "120px" },
              color: "white",
              bgcolor: "rgba(38,179, 240,1) ",
              ".MuiMenuItem-root": {
                fontFamily: "poppins !important",
              },
              // boxShadow: " 0px 4px 6px 0px rgba(0,0,0,0.3)",
            }}
            onChange={handleAToZChange}
          >
            <MenuItem value={1}> {`Active`} </MenuItem>
            <MenuItem value={2}>{`InActive`}</MenuItem>
          </Select>
        </Box>
      </Box> */}
      <Box sx={{ px: { xs: 3, md: 1 } }}>
        <Carousel>
          {productsByCat[0]?.products?.length > 0 ? (
            productsByCat[0]?.products?.map((item, index) => (
              <PurchaseCard
                index={index}
                checked={checked}
                handleChange={handleChange}
                setChecked={setChecked}
                item={item}
                cat={selected}
              ></PurchaseCard>
            ))
          ) : (
            <Typography sx={{ fontWeight: "bold", fontSize: "18px", py: 12 }}>
              No products in this category
            </Typography>
          )}
        </Carousel>
      </Box>
    </Box>
  );
}

export default AuctionSetting;
