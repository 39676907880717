import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box } from "@mui/system";
import { Button, Grid, Typography } from "@mui/material";
// COMPONENTS
import VerificationCodeField from "./VerificationCodeField";

function CodeVerification() {
  // GLOBAL VARIABLE
  const numRegex = /^[0-9]+$/;

  // FORMIK
  const initialValues = {
    field1: "",
    field2: "",
    field3: "",
    field4: "",
    field5: "",
    field6: "",
  };

  const validationSchema = yup.object().shape({
    field1: yup.string().required().matches(numRegex, ""),
    field2: yup.string().required().matches(numRegex, ""),
    field3: yup.string().required().matches(numRegex, ""),
    field4: yup.string().required().matches(numRegex, ""),
    field5: yup.string().required().matches(numRegex, ""),
    field6: yup.string().required().matches(numRegex, ""),
  });

  const onSubmit = (values, { resetForm }) => {
    const { field1, field2, field3, field4, field5, field6 } = values;
    let code = field1 + field2 + field3 + field4 + field5 + field6;

    alert(JSON.stringify(code));
    // setCountDownState(true);
  };

  // HANDLERS
  const handleChangeNextField = (e) => {
    const { maxLength, value, name } = e.target;
    const [fieldName, fieldIndex] = name.split("d");

    let fieldIntIndex = parseInt(fieldIndex, 6);

    if (value.length >= maxLength) {
      if (fieldIntIndex < 6) {
        const nextfield = document.querySelector(
          `input[name=field${fieldIntIndex + 1}]`
        );

        if (nextfield !== null) {
          nextfield.focus();
        }
      }
    }
  };

  // FORMIK
  const formik = useFormik({ initialValues, validationSchema, onSubmit });

  return (
    <Box component="form" onSubmit={formik.handleSubmit}>
      <Box
        mx={{
          sm: 20,
          md: 5,
        }}
      >
        <Grid
          container
          spacing={{
            xs: 1,
            sm: 2,
            md: 1,
          }}
          sx={{ width: "100%" }}
        >
          <Grid item xs={2}>
            <VerificationCodeField
              inputProps={{
                maxLength: 1,
              }}
              onChange={handleChangeNextField}
              name="field1"
              formik={formik}
            />
          </Grid>
          <Grid item xs={2}>
            <VerificationCodeField
              inputProps={{
                maxLength: 1,
              }}
              onChange={handleChangeNextField}
              name="field2"
              formik={formik}
            />
          </Grid>
          <Grid item xs={2}>
            <VerificationCodeField
              inputProps={{
                maxLength: 1,
              }}
              onChange={handleChangeNextField}
              name="field3"
              formik={formik}
            />
          </Grid>
          <Grid item xs={2}>
            <VerificationCodeField
              inputProps={{
                maxLength: 1,
              }}
              onChange={handleChangeNextField}
              name="field4"
              formik={formik}
            />
          </Grid>
          <Grid item xs={2}>
            <VerificationCodeField
              inputProps={{
                maxLength: 1,
              }}
              onChange={handleChangeNextField}
              name="field5"
              formik={formik}
            />
          </Grid>
          <Grid item xs={2}>
            <VerificationCodeField
              inputProps={{
                maxLength: 1,
              }}
              onChange={handleChangeNextField}
              name="field6"
              formik={formik}
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ width: "100%" }}>
        <Typography
          sx={{
            fontFamily: "poppins",
            py: 2,
            textAlign: "center",
            fontSize: "14px",
            color: "#fff",
          }}
        >
          Enter 6 digit verification code
        </Typography>
      </Box>
      <Box className={"flexCenter"} sx={{ my: 2 }}>
        <Button
          sx={{
            fontFamily: "poppins",
            boxShadow: "0 8px 6px -2px rgba(0, 0, 0, 0.2)",
            px: 3,
            borderRadius: "10px",
            textTransform: "none",
            height: "58px",
            backgroundColor: "#58E6FF",
            color: "white",
            width: { xs: "100%" },
            "&:hover": {
              transform: "scale(1.01)",
              bgcolor: "#FFB501",
            },
            fontSize: 20,
          }}
          variant="contained"
          type={"submit"}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          verify
        </Button>
      </Box>
    </Box>
  );
}

export default CodeVerification;
