import "./App.css";
import "./index.css";
import * as React from "react";
import Router from "./routes/index";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./utils/Loader";
const toastOptions = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: false,
  pauseOnHover: true,
};

function App() {
  return (
    <div className="App">
      <ToastContainer {...toastOptions} />
      <React.Suspense fallback={<Loader />}>
        <Router />
      </React.Suspense>
    </div>
  );
}

export default App;
