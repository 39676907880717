import React from "react";
import { Box, Typography, Container, Grid } from "@mui/material";
import cat3 from "../../../assets/images/cat1.png";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import Avatar from "@mui/material/Avatar";
const { REACT_APP_HOST } = process.env;

const items = [
  {
    id: 1,
    title: "item #1",
    image: cat3,
    count: "22",
    price: "10000",
    year: "2002",
  },
  {
    id: 2,
    title: "item #2",
    image: cat3,
    count: "4",
    price: "20000",
    year: "2016",
  },
  {
    id: 3,
    title: "item #3",
    image: cat3,
    count: "65",
    price: "700",
    year: "2020",
  },
  {
    id: 4,
    title: "item #4",
    image: cat3,
    count: "70",
    price: "9000",
    year: "2002",
  },
  {
    id: 5,
    title: "item #5",
    image: cat3,
    count: "1",
    price: "3000",
    year: "2009",
  },
  {
    id: 6,
    title: "item #6",
    image: cat3,
    count: "1",
    price: "3000",
    year: "2009",
  },
  {
    id: 7,
    title: "item #7",
    image: cat3,
    count: "1",
    price: "3000",
    year: "2009",
  },
  {
    id: 8,
    title: "item #8",
    image: cat3,
    count: "1",
    price: "3000",
    year: "2009",
  },
  {
    id: 9,
    title: "item #9",
    image: cat3,
    count: "1",
    price: "3000",
    year: "2009",
  },
];
function EndedBids({ wonBids }) {
  return (
    <Container>
      {wonBids.length > 0 ? (
        <Grid container sx={{ pb: { md: 15 } }} pt={5}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {wonBids?.map((item) => {
                return (
                  <Grid item sm={6} md={6} lg={4}>
                    <Grid
                      container
                      // bgcolor="black"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        item
                        md={12}
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "row", md: "column" },
                          // justifyContent: { xs: "space-between" },
                          alignItems: "flex-start",
                          pl: { md: 1 },
                          pt: { xs: 3, md: 1 },
                        }}
                      >
                        {/* <Typography
                        // color="white"
                        color="#005371"
                        fontFamily="Raleway, sans-serif"
                      >
                        Sold By: Merry Harry
                      </Typography> */}
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Box
                          sx={{
                            borderRadius: {
                              xs: "20px 20px 0px 0px ",
                              md: "20px 20px 20px 20px ",
                            },
                            boxShadow:
                              " 2px 3px 14px 2px rgba(185,237,255,0.75)",
                            border: "2px solid rgb(185,237,255) !important",
                          }}
                        >
                          <Box
                            component="img"
                            sx={{
                              cursor: "pointer",
                              // height: "150px",
                              borderRadius: {
                                xs: "20px 20px 0px 0px",
                                md: "20px 20px 20px 20px",
                              },
                              width: { xs: "100%", sm: "100%", md: "100%" },
                              height: {
                                xs: "100%",
                                sm: "100%",
                                md: "180px !important",
                              },
                              display: "block",
                              overflow: "hidden",
                              objectFit: "cover",
                            }}
                            src={`${REACT_APP_HOST}${item?.images[0].url}`}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        position="relative"
                        bgcolor="#26B3F0"
                        sx={{
                          py: 2,
                          borderRadius: {
                            xs: "0px 0px 10px 10px",
                            md: "0px 10px 10px 0px",
                          },
                        }}
                        //   sx={{ height: { md: 350 }, width: "100%" }}
                      >
                        <Box>
                          <Box
                            width={"100%"}
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Avatar sx={{ bgcolor: "#FFB501" }}>
                              <AccountBoxIcon />
                            </Avatar>
                            <Typography
                              color="white"
                              fontFamily="Raleway, sans-serif"
                              sx={{
                                fontSize: { md: 12 },
                                //   fontWeight: "bold !important",
                              }}
                            >
                              {item?.bids[0]?.user?.username}
                            </Typography>
                            <Typography
                              color="white"
                              fontFamily="Raleway, sans-serif"
                              sx={{
                                fontSize: { md: 12 },
                                //   fontWeight: "bold !important",
                              }}
                            >
                              Bid Number: {item?.bids[0]?.id}
                            </Typography>
                            <Typography
                              color="white"
                              fontFamily="Raleway, sans-serif"
                              sx={{
                                fontSize: { md: 12 },
                              }}
                            >
                              Bid Amount: $ {item?.bids[0]?.amount}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sx={{
                          display: "flex",
                          flexDirection: { xs: "column", md: "column" },
                          // justifyContent: { xs: "space-between" },
                          alignItems: "flex-start",
                          pl: { md: 3 },
                          pt: { xs: 3, md: 1 },
                        }}
                      >
                        <Typography
                          // color="white"
                          color="#005371"
                          fontFamily="Raleway, sans-serif"
                        >
                          Price: {item?.price}
                        </Typography>
                        <Typography
                          // color="white"
                          color="#005371"
                          fontFamily="Raleway, sans-serif"
                        >
                          Year: {item?.year}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography
          sx={{
            height: "100px",
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          No Product Found
        </Typography>
      )}
    </Container>
  );
}

export default EndedBids;
