import axios from "../axiosConfig";

class Routes {
  login(data) {
    return axios.post("/login", data);
  }
  register(data) {
    return axios.post("/register", data);
  }
  logout() {
    return axios.post("/logout");
  }
}

export default new Routes();
