import React, { useState, useEffect } from "react";
import { Modal, Button, Typography, Box, TextField } from "@mui/material";
import { toast } from "react-toastify";
import AddImageCard from "../EditProduct/AddImage";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CategoryAPI } from "../../../api";

function MyModal({ handleClose, open, setBidValue, id, setisChanged }) {
  const [imagesArr, setImagesArr] = useState([]);
  const [category, setCategory] = useState({});
  const initialValues = {
    name: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  function onSubmit(values) {
    console.log(" edit imagesArr", imagesArr, values);
    if (imagesArr.length > 0) {
      let newImage = imagesArr[imagesArr.length - 1];
      values.image = newImage;
      console.log("name", values.name);
      console.log("newImage", newImage);
      let data = {
        name: values.name,
        image: newImage,
        _method: "put",
      };
      console.log("cat values", values);
      CategoryAPI.editCategory(data, id).then((res) => {
        if (res?.data.status == 200) {
          toast.success("Category Updated Successfully");
          handleClose();
        } else {
          toast.error(res?.data.message);
        }
      });
    } else {
      toast.warning("Image are required");
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  useEffect(() => {
    console.log("idid", id);
    CategoryAPI.allCategories().then((res) => {
      let editCategory = res?.data?.data?.filter((item) => item.id == id);
      setCategory(editCategory[0]);
      console.log("editCategory", [editCategory[0]?.image.url]);
      formik.setFieldValue("name", editCategory[0]?.name);
      setImagesArr([editCategory[0]?.image.url]);
    });
  }, [id]);

  return (
    <div>
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ bgcolor: "rgba(0,0,0,0.5)" }}
      >
        <Box
          component="form"
          onSubmit={formik.handleSubmit}
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
        >
          <Typography
            sx={{
              bgcolor: "rgba(38,179, 240,1)",
              color: "white",
              textAlign: "center",
              fontSize: { xs: 18, md: 22, xl: 28 },
              py: 1,
            }}
            variant="h6"
            component="h2"
            fontFamily="Raleway, sans-serif"
            fontWeight="bold"
          >
            Edit Category
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              //   bgcolor: "red",
              width: "100%",
              pt: 4,
            }}
          >
            <AddImageCard
              noOfImages={1}
              imagesArr={imagesArr}
              setImages={setImagesArr}
            />
          </Box>

          <Box
            width="100%"
            py={2}
            sx={{ display: "flex", justifyContent: "center" }}
          >
            <TextField
              sx={{
                boxShadow: "0px 0px 8px 0px rgba(50, 50, 50, 0.5)",
                ".MuiOutlinedInput-root": {
                  outline: "none",
                },
                ".MuiInputBase-input": {
                  //   bgcolor: "rgba(38,179, 240,0.6)",
                  //   color: "#ffff",
                  "::placeholder ": {
                    // color: "#fff",
                    fontFamily: "Raleway, sans-serif !important",
                  },
                  fontFamily: "Raleway, sans-serif",
                },
              }}
              placeholder="Category Title"
              variant="outlined"
              name="name"
              value={formik?.values?.name}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              error={formik?.touched?.name && Boolean(formik.errors?.name)}
              helperText={formik?.touched?.name && formik?.errors?.name}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              py: 1,
            }}
          >
            <Button
              sx={{
                fontFamily: "poppins",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.6)",
                // px: 3,
                py: 1,
                backgroundColor: "#26B3F0",
                color: "white",
                width: { md: "100px" },
                "&:hover": {
                  transform: "scale(1.1)",
                  bgcolor: "#FFB501",
                },
                fontSize: 14,
              }}
              variant="contained"
              type="submit"
            >
              Add
            </Button>
            <Button
              sx={{
                fontFamily: "poppins",
                boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.6)",
                // px: 3,
                py: 1,
                backgroundColor: "white",
                color: "#26B3F0",
                width: { md: "100px" },
                "&:hover": {
                  transform: "scale(1.1)",
                  bgcolor: "#FFB501",
                },
                fontSize: 14,
                mx: 2,
              }}
              onClick={handleClose}
              variant="contained"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default MyModal;
