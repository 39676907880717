import React, { useState, useEffect } from "react";
import { Box, Button, Paper, Typography } from "@mui/material";
import Carousel from "../../../utils/Carousel";
import BuyerCard from "./BuyerCard";
import Subheading from "../../../utils/HeadingAdmin";
import { BuyerAPI } from "../../../api";
import Loader from "../../../utils/Loader";
const { REACT_APP_HOST } = process.env;
let users = [
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
];

function BuyerSeller() {
  const [buyers, setBuyers] = useState([]);
  const [change, setChange] = useState(false);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    BuyerAPI.buyerTransections().then((res) => {
      let buyers = res?.data?.data.filter((item) => item.role == "buyer");
      setBuyers(buyers);
      setisLoading(false);
    });
  }, []);
  useEffect(() => {
    BuyerAPI.buyerTransections().then((res) => {
      let buyers = res?.data?.data.filter((item) => item.role == "buyer");
      setBuyers(buyers);
      setisLoading(false);
      setChange(false);
    });
  }, [change]);
  return (
    <Box pb={5}>
      <Box className={"centerRow"} sx={{ py: { md: 1 }, pb: { xs: 2, md: 0 } }}>
        <Subheading>Buyers</Subheading>
      </Box>
      <Box sx={{ px: { xs: 3, md: 1 } }}>
        <Loader active={isLoading} />
        {buyers?.length > 0 ? (
          <Carousel>
            {buyers?.map((user, index) => {
              return (
                <BuyerCard user={user} key={index} setChange={setChange} />
              );
            })}
          </Carousel>
        ) : (
          <Typography sx={{ textAlign: "center" }}>No Buyer Found</Typography>
        )}
      </Box>
    </Box>
  );
}

export default BuyerSeller;
