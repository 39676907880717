import React, { useState, useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import userImg from "../../../assets/images/Buyer.png";
import { useParams } from "react-router-dom";
import { CategoryAPI, ProductAPI } from "../../../api";
import Loader from "../../../utils/Loader";

const { REACT_APP_HOST } = process.env;

function ProductDetail() {
  let { id, cat } = useParams();
  const [product, setProduct] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [productCategory, setproductCategory] = useState({});

  // useEffect(() => {
  //   console.log("id cat", id, cat);
  // }, []);
  useEffect(() => {
    CategoryAPI.allCategories().then((res) => {
      let productCategory = res?.data.data.filter(
        (category) => category.id == cat
      );
      setproductCategory(productCategory[0]);
      console.log("productCategory", productCategory);
      let temp = res?.data.data?.map((cat) =>
        cat?.products?.filter((product) => product.id == id)
      );
      let filterProduct = temp.filter((item) => item.length > 0);
      filterProduct = filterProduct[0];
      let passedProduct = filterProduct[0];
      setProduct(passedProduct);
      console.log("passed Detail", passedProduct);
      setisLoading(false);
    });
  }, []);
  return (
    <Box
      className="centerRow"
      sx={{ flexDirection: "column", alignItems: "center", pb: 10 }}
    >
      <Grid
        sx={{ width: { xs: "80%", md: "60%" }, pb: 2 }}
        container
        spacing={2}
      >
        <Grid item xs={12}>
          <Box
            className={"centerRow"}
            sx={{ mb: 3, bgcolor: "#024861", mt: 8 }}
          >
            <Typography
              sx={{
                fontWeight: "bold",
                fontSize: { xs: 26, md: 22, lg: 24 },
                color: "white",
                fontFamily: "poppins",
                py: 1,
              }}
            >
              Product Detail
            </Typography>
          </Box>
        </Grid>
        <Grid item md={12}>
          <Loader active={isLoading} />

          <Grid container spacing={2} className={"centerRow"}>
            {product?.images?.map((item) => {
              return (
                <Grid key={item.id} item xs={12} md={2.3}>
                  <Box
                    sx={{ width: "100%", height: "100px" }}
                    component="img"
                    src={`${REACT_APP_HOST}${item?.url}`}
                  ></Box>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 26, md: 22, lg: 24 },
              color: "#024861",
              fontFamily: "poppins",
              py: 1,
            }}
          >
            Product Name
          </Typography>
          <Typography>{product?.name}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 26, md: 22, lg: 24 },
              color: "#024861",
              fontFamily: "poppins",
              py: 1,
            }}
          >
            Category
          </Typography>
          <Typography>{productCategory.name}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 26, md: 22, lg: 24 },
              color: "#024861",
              fontFamily: "poppins",
              py: 1,
            }}
          >
            Price
          </Typography>
          <Typography>{product?.price}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 26, md: 22, lg: 24 },
              color: "#024861",
              fontFamily: "poppins",
              py: 1,
            }}
          >
            Year
          </Typography>
          <Typography>{product?.year}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 26, md: 22, lg: 24 },
              color: "#024861",
              fontFamily: "poppins",
              py: 1,
            }}
          >
            Area
          </Typography>
          <Typography>{product?.area}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 26, md: 22, lg: 24 },
              color: "#024861",
              fontFamily: "poppins",
              py: 1,
            }}
          >
            Location
          </Typography>
          <Typography>{product?.location}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 26, md: 22, lg: 24 },
              color: "#024861",
              fontFamily: "poppins",
              py: 1,
            }}
          >
            Quantity
          </Typography>
          <Typography>{product?.quantity}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: { xs: 26, md: 22, lg: 24 },
              color: "#024861",
              fontFamily: "poppins",
              py: 1,
            }}
          >
            Description
          </Typography>
          <Typography>{product?.description}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductDetail;
