import * as React from "react";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

const VerificationCodeField = ({
  onChange,
  type = "text",
  name,
  formik,
  ...props
}) => {
  return (
    <>
      <Box
        sx={{
          borderBottom: "3px solid #fff",
          width: "100%",
          // p: "3px",
          // position: "relative",
          // borderRadius: "10px",
          // width: "auto",
          // background:
          //   formik?.touched[name] && formik?.errors[name]
          //     ? "#FF0000"
          //     : "linear-gradient(180deg, rgba(96,32,178,1) 26%, rgba(17,168,253,1) 54%)",
        }}
      >
        <TextField
          fullWidth
          type={type}
          name={name}
          value={formik?.values[name]}
          onChange={(e) => {
            onChange(e);
            formik?.handleChange(e);
          }}
          sx={{
            color: "#005371",
            cursor: "pointer",
            fontFamily: "Poppins",
            ".MuiInputBase-input": {
              cursor: "pointer",
              fontSize: "18px",
              fontFamily: "Poppins",
              padding: 0,
              pb: 1,
              px: 1,
              color: "white",
              textAlign: "left",
              "::placeholder": {
                fontFamily: "Poppins",
                fontSize: "18px",
                color: "rgba(255,255,255,1) !important",
                textAlign: "left",
              },
            },

            width: "40px",
            height: "35px",
            ".MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          placeholder="0"
          onBlur={formik?.handleBlur}
          error={formik?.touched[name] && Boolean(formik?.errors[name])}
          {...props}
        />
      </Box>
    </>
  );
};

export default VerificationCodeField;
