import React from "react";
import { Box, Button, Paper, Typography, Checkbox } from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import { useNavigate } from "react-router-dom";
import { ProductAPI } from "../../../api";
import { toast } from "react-toastify";
function BidsCard({
  item,
  cat,
  index,
  setChange,
  checked,
  handleChange,
  setChecked,
}) {
  // console.log("items", item);
  let navigate = useNavigate();
  function ChangeStatus(id) {
    ProductAPI.changeProductStatus(id).then((res) => {
      toast.success("Product Status Changed");
      setChange(true);
    });
  }
  return (
    <div>
      <Box
        className={"centerColumn"}
        sx={{ py: { xs: 4, md: 0 }, px: { md: 2 }, py: { md: 4 } }}
      >
        {/* {console.log("item-card", item)} */}
        <Paper
          elevation={12}
          sx={{
            cursor: "pointer",
            borderRadius: "15%",
            // width: { xs: "190px", md: "200px", lg: "220px" },
            width: { xs: "170px", md: "170px", lg: "200px" },
            height: { xs: "170px", md: "170px", lg: "200px" },
            // bgcolor: "rgba(255,255, 255,0.4) !important",
            backgroundImage:
              item?.images.length > 0
                ? `url(${process.env.REACT_APP_HOST}${item?.images[0]?.url})`
                : "none",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "10px",
            position: "relative",
            border: "3px solid #B9EDFF",
            p: "5%",
          }}
        >
          <Box
            sx={{
              width: { xs: "40px", md: "50px" },
              height: { xs: "40px", md: "50px" },
              borderRadius: "50%",
              bgcolor: "red",
              position: "absolute",
              bgcolor: "rgba(38,179, 240,1) !important",
              transformOrigin: "90% 40% !important",
              top: -20,
              left: -20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              className={"centerColumn"}
              sx={{ color: "white", fontSize: { xs: 10, md: 13 } }}
            >
              {item?.bids?.length}
              <Typography sx={{ color: "white", fontSize: { xs: 10, md: 13 } }}>
                Bids
              </Typography>
            </Typography>
          </Box>
          <Box bgcolor="white" width="auto">
            <Checkbox
              icon={
                <Box
                  width="33px"
                  height="33px"
                  bgcolor="white"
                  border="2px solid #0a8241"
                />
              }
              checkedIcon={<CheckBoxOutlinedIcon />}
              sx={{
                ".MuiSvgIcon-root": {
                  fill: "#0a8241",
                  fontSize: 50,
                  fontWeight: 100,
                },
                position: "absolute",
                top: -30,
                right: -30,
                width: 60,
                height: 60,
              }}
              checked={item?.id == checked ? true : false}
              onClick={() => handleChange(item?.id)}
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              justifyContent: { xs: "flex-end", md: "space-around" },
              alignItems: { xs: "center", md: "flex-end" },
              // bgcolor: "red",
              height: "100%",
            }}
          >
            <Button
              sx={{
                width: { xs: "100%", md: "60%" },
                fontSize: { xs: 8, md: 10 },
                bgcolor: "rgba(38,179, 240,1)  !important",
                mb: { xs: 1, md: 0 },
                fontFamily: "Poppins, sans-serif",
              }}
              variant="contained"
              onClick={() =>
                navigate(`/admin/product-detail/${cat}/${item.id}`)
              }
            >
              Details
            </Button>
          </Box>
        </Paper>
        <Typography
          // variant="h5"
          // align="center"
          color="#024861"
          fontFamily="Raleway, sans-serif"
          fontWeight="bold"
          sx={{ fontSize: { xs: 18, md: 16, xl: 28 } }}
        >
          Price: $ {item?.price}
        </Typography>
        <Typography
          // variant="h5"
          // align="center"
          color="#024861"
          fontFamily="Raleway, sans-serif"
          fontWeight="bold"
          sx={{ fontSize: { xs: 18, md: 16, xl: 28 } }}
        >
          Year: {item?.year}
        </Typography>
        <Typography
          // variant="h5"
          // align="center"
          color="#024861"
          fontFamily="Raleway, sans-serif"
          fontWeight="bold"
          sx={{ fontSize: { xs: 18, md: 16, xl: 28 } }}
        >
          Status: {item?.status == 1 ? "Active" : "In Active"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "flex-end", md: "space-around" },
            alignItems: { xs: "center", md: "flex-end" },
            // bgcolor: "red",
            height: "100%",
          }}
        >
          <Button
            sx={{
              width: { xs: "150px", md: "150px", lg: "180px" },
              fontSize: { xs: 8, md: 12 },
              bgcolor: "rgba(38,179, 240,1)  !important",
              mb: { xs: 1, md: 0 },
              fontFamily: "Poppins, sans-serif",
            }}
            variant="contained"
            onClick={() => ChangeStatus(item?.id)}
          >
            {item?.status == 1 ? "In Active" : "Active"}
          </Button>
        </Box>
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: { xs: "flex-end", md: "center" },
            alignItems: { xs: "center", md: "flex-end" },
            // bgcolor: "#ffff",
            height: "100%",
            width: "100%",
          }}
        >
          <Button
            sx={{
              width: { xs: "100%", md: "90%" },
              fontSize: { xs: 8, md: 10 },
              bgcolor: "rgba(38,179, 240,1)  !important",
              mb: { xs: 1, md: 0 },
              fontFamily: "Poppins, sans-serif",
            }}
            variant="contained"
          >
            Bought For: {item?.amount}
          </Button>
        </Box> */}
      </Box>
    </div>
  );
}

export default BidsCard;
