import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import Residence from "../../../assets/images/Residence.png";
import Commercial from "../../../assets/images/Commercial.png";
import car from "../../../assets/images/car.png";
import shop from "../../../assets/images/shop.png";
import genral from "../../../assets/images/genral.png";
import mechine from "../../../assets/images/mechine.png";
import Subheading from "../../../utils/HeadingAdmin";
import Carousel from "../../../utils/Carousel";
import PurchaseCard from "./ProductCard";
import cat1 from "../../../assets/images/cat1.png";
import { CategoryAPI } from "../../../api";
import Loader from "../../../utils/Loader";
const { REACT_APP_HOST } = process.env;

const WonBids = [
  {
    id: 1,
    title: "item #1",
    image: cat1,
    count: "22",
    bidNo: "22",
    amount: "10000",
    price: "10000",
    year: "2002",
  },
  {
    id: 2,
    title: "item #2",
    image: cat1,
    count: "4",
    price: "20000",
    year: "2016",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 3,
    title: "item #3",
    image: cat1,
    count: "65",
    price: "700",
    year: "2020",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 4,
    title: "item #4",
    image: cat1,
    count: "70",
    price: "9000",
    year: "2002",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 5,
    title: "item #5",
    image: cat1,
    count: "1",
    price: "3000",
    year: "2009",
    bidNo: "22",
    amount: "10000",
  },
];
let Cats = [
  {
    image: Commercial,
    title: "Commercial",
    count: "99+",
  },
  {
    image: Residence,
    title: "Residence",
    count: "233",
  },

  {
    image: car,
    title: "Cars",
    count: "12",
  },
  {
    image: shop,
    title: "Shops",
    count: "99",
  },
  {
    image: genral,
    title: "Genral Items",
    count: "233",
  },
  {
    image: mechine,
    title: "Mechinery",
    count: "1",
  },
];

function ViewAuction() {
  const [selected, setSelected] = useState(1);
  const [unWonBids, setunWonBids] = useState([]);
  const [categories, setCategories] = useState([]);
  const [productsByCat, setProductsByCat] = useState([]);
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    CategoryAPI.allCategories().then((res) => {
      setCategories(res.data.data);
      let newArr = res?.data.data?.filter((item) => item.id == selected);

      setProductsByCat(newArr);
      setisLoading(false);
    });

    CategoryAPI.productsByCategories(selected).then((res) => {
      setunWonBids(res?.data.data.unsold);
    });
  }, []);

  useEffect(() => {
    setisLoading(true);

    CategoryAPI.allCategories().then((res) => {
      setCategories(res.data.data);
      let newArr = res?.data.data?.filter((item) => item.id == selected);

      setProductsByCat(newArr);
      setisLoading(false);
    });

    CategoryAPI.productsByCategories(selected).then((res) => {
      setunWonBids(res?.data.data.unsold);
    });
  }, [selected]);
  return (
    <Box height="100%" pb={5} width="100%">
      <Loader active={isLoading} />
      <Box className={"centerRow"} sx={{ pb: 5 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 26, md: 22, lg: 24 },
            color: "#024861",
            fontFamily: "poppins",
            mt: 8,
          }}
        >
          Select Category
        </Typography>
      </Box>
      <Grid container spacing={2} px={"5%"}>
        {categories?.map((cat, index) => {
          return (
            <Grid
              item
              key={cat?.name}
              xs={6}
              sm={4}
              md={4}
              lg={2}
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems="center"
            >
              <Paper
                elevation={3}
                sx={{
                  // zIndex: 90,
                  cursor: "pointer",
                  width: {
                    xs: "80px",
                    md: selected == cat?.id ? "140px" : "120px",
                  },
                  height: {
                    xs: "80px",
                    md: selected == cat?.id ? "140px" : "120px",
                  },
                  bgcolor:
                    selected == cat?.id
                      ? "rgba(61,197, 255,0.75) "
                      : "rgba(158,228, 253,0.4) !important",
                  borderRadius: "10px",
                  // position: "relative",
                  p: "5%",
                }}
                onClick={() => {
                  // navigate("/auction");
                  setSelected(cat?.id);
                }}
              >
                <Box
                  component="img"
                  sx={{
                    width: "100%",
                    height: "100%",
                    // width: { xs: "50%", sm: "60%", md: "100%" },
                    // heigth: { xs: "50%", sm: "60%", md: "100%" },
                    // display: "block",
                    overflow: "hidden",
                    objectFit: "contain",
                  }}
                  src={`${REACT_APP_HOST}${cat?.image?.url}`}
                />
              </Paper>
              <Typography
                // variant="h5"
                // align="center"
                color="#024861"
                fontFamily="Raleway, sans-serif"
                fontWeight="bold"
                sx={{ fontSize: { xs: 18, md: 16, xl: 28 }, py: 2 }}
              >
                {cat?.title}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Box className={"centerRow"} sx={{ pb: 6 }}>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: { xs: 26, md: 22, lg: 24 },
            color: "#024861",
            fontFamily: "poppins",
            mt: 8,
          }}
        >
          Current Products in Auction
        </Typography>
      </Box>
      {unWonBids?.length > 0 ? (
        <Box sx={{ px: { xs: 3, md: 1 }, py: 4 }}>
          <Carousel>
            {unWonBids?.map((item) => (
              <PurchaseCard item={item} cat={selected}></PurchaseCard>
            ))}
          </Carousel>
        </Box>
      ) : (
        <Typography
          sx={{
            height: "100px",
            width: "100%",
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          No Product Found
        </Typography>
      )}
    </Box>
  );
}

export default ViewAuction;
