import React from "react";
import { Routes, Route } from "react-router-dom";

import AuctionCategories from "../../components/Admin/AuctionCategories/AuctionCategories";
import ViewAuctions from "../../components/Admin/ViewAuction/ViewAuction";
import AuctionSettings from "../../components/Admin/AuctionSettings/AuctionSettings";
import BuyerSeller from "../../components/Admin/BuyerSellers/BuyerSeller";
import ViewBids from "../../components/Admin/ViewBids/ViewBids";
import ViewTransections from "../../components/Admin/ViewTransection/ViewTransection";
import PaymentGateways from "../../components/Admin/PaymentGateways/PaymentGateways";
import MyProducts from "../../components/Admin/MyProducts/MyProducts";
import AddProduct from "../../components/Admin/AddProduct/Index";
import EditProduct from "../../components/Admin/EditProduct/Index";
import ProductDetail from "../../components/Admin/ProductDetail/ProductDetail";

import Loader from "../../utils/Loader";
function AdminRoutes() {
  return (
    <React.Suspense fallback={<Loader active={true} />}>
      <Routes>
        <Route path="/auction-categories" element={<AuctionCategories />} />
        <Route path="/view-auction" element={<ViewAuctions />} />
        <Route path="/auction-settings" element={<AuctionSettings />} />
        <Route path="/buyer-sellers" element={<BuyerSeller />} />
        <Route path="/view-bids" element={<ViewBids />} />
        <Route path="/view-transactions" element={<ViewTransections />} />
        <Route path="/payment-gateways" element={<PaymentGateways />} />
        <Route path="/my-products" element={<MyProducts />} />
        <Route path="/add-product" element={<AddProduct />} />
        <Route path="/edit-product/:cat/:id" element={<EditProduct />} />
        <Route path="/product-detail/:cat/:id" element={<ProductDetail />} />
      </Routes>
    </React.Suspense>
  );
}
export default AdminRoutes;
