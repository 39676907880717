import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Paper, Button } from "@mui/material";
import AddImageCard from "./AddImage";
import addIcon from "../../../assets/images/addImg.png";
import InputField from "./InputField";
import SelectField from "./SelectField";
import MultilineInputField from "./MultilineInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CategoryAPI, ProductAPI } from "../../../api";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../../utils/Loader";
import { useNavigate } from "react-router-dom";
const { REACT_APP_HOST } = process.env;
function Index() {
  const [firstImg, setFirstImg] = useState(null);
  const [secondImg, setSecondImg] = useState(null);
  const [thirdImg, setThirdImg] = useState(null);
  const [fourthImg, setFourthImg] = useState(null);
  const [fifthImg, setFifthImg] = useState(null);
  const [product, setProduct] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const [imagesArr, setImagesArr] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState(1);

  // const [name, setName] = useState("");
  // const [price, setPrice] = useState("");
  // const [year, setYear] = useState("");
  // const [area, setArea] = useState("");
  // const [location, setLocation] = useState("");
  // const [quantity, setQuantity] = useState("");
  // const [category, setCategory] = useState(1);
  // const [description, setDescription] = useState("");

  function AllImages(file) {
    setImagesArr((state) => {
      let prev = state;
    });
  }

  const initialValues = {
    name: "",
    price: "",
    year: "",
    category_id: 1,
    area: "",
    location: "",
    quantity: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    price: Yup.string().required("Price is required"),
    year: Yup.string().required("Year is required"),
    category_id: Yup.string().required("Category is required"),
    area: Yup.string().required("Area is required"),
    location: Yup.string().required("Location is required"),
    quantity: Yup.string().required("Quantity is required"),
    description: Yup.string().required("Description is required"),
  });

  let { id, cat } = useParams();
  useEffect(() => {
    CategoryAPI.allCategories().then((res) => {
      setCategories(res?.data?.data);
      let temp = res?.data?.data?.map((cat) =>
        cat?.products?.filter((product) => product.id == id)
      );
      let filterProduct = temp?.filter((item) => item.length > 0);
      filterProduct = filterProduct[0];
      let passedProduct = filterProduct[0];
      let oldImages = passedProduct?.images.map((item) => {
        // let img = `${REACT_APP_HOST}${item?.url}`;
        let img = `${item?.url}`;
        return img;
      });
      setImagesArr(oldImages);
      // setImagesArr(passedProduct);
      formik.setFieldValue("name", passedProduct?.name);
      formik.setFieldValue("price", passedProduct?.price);
      formik.setFieldValue("year", passedProduct?.year);
      formik.setFieldValue("area", passedProduct?.area);
      formik.setFieldValue("location", passedProduct?.location);
      formik.setFieldValue("category", cat);
      formik.setFieldValue("quantity", passedProduct?.quantity);
      formik.setFieldValue("description", passedProduct?.description);
      setProduct(passedProduct);
      setisLoading(false);
    });
  }, []);

  function onSubmit(values) {
    const formData = new FormData();
    let newArray = imagesArr.map((img) => {
      if (img[0] == "/" && img[1] == "9") {
        return img;
      } else {
        let cuted = img.split("/storage/");
        return cuted[1];
      }
    });
    newArray.length > 0 && (values.images = newArray);
    values._method = "put";
    console.log("newArray", newArray);
    formData.append("name", values.name);
    formData.append("price", values.price);
    formData.append("year", values.year);
    formData.append("category_id", values.category);
    formData.append("area", values.area);
    formData.append("location", values.location);
    formData.append("quantity", values.quantity);
    formData.append("description", values.description);
    const newvalues = [...formData.entries()];
    console.log("values", values);
    ProductAPI.editProduct(id, values).then((res) => {
      if (res?.data?.status == 200) {
        toast.success("Product Updated");
        navigate("/admin/auction-categories");
      } else {
        toast.error(res?.data?.message);
      }
    });
  }

  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit,
  });

  let navigate = useNavigate();

  return (
    <div>
      <Box
        enctype="multipart/form-data"
        component="form"
        onSubmit={formik.handleSubmit}
        className="centerRow"
        sx={{ flexDirection: "column", alignItems: "center", pb: 10 }}
      >
        <Loader active={isLoading} />
        <Grid
          sx={{ width: { xs: "80%", md: "60%" }, pb: 2 }}
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <Box className={"centerRow"} sx={{ pb: 3 }}>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: { xs: 26, md: 22, lg: 24 },
                  color: "#024861",
                  fontFamily: "poppins",
                  mt: 8,
                }}
              >
                Edit Product
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={12}>
            <AddImageCard imagesArr={imagesArr} setImages={setImagesArr} />
          </Grid>
        </Grid>
        <Grid sx={{ width: { xs: "80%", md: "60%" } }} container spacing={2}>
          <Grid
            item
            sm={6}
            width="100%"
            // sx={{ display: "flex", justifyContent: "center" }}
          >
            <InputField name="name" formik={formik} placeholder="Name" />
          </Grid>
          <Grid item sm={6} width="100%">
            <SelectField
              name="category_id"
              array={categories}
              formik={formik}
            />
          </Grid>
          <Grid item sm={6} width="100%">
            <InputField placeholder="Price" name="price" formik={formik} />
          </Grid>
          <Grid item sm={6} width="100%">
            <InputField placeholder="Year" name="year" formik={formik} />
          </Grid>
          <Grid item sm={6} width="100%">
            <InputField placeholder="Area (ft)" name="area" formik={formik} />
          </Grid>
          <Grid item sm={6} width="100%">
            <InputField
              placeholder="Location"
              name="location"
              formik={formik}
            />
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            sm={12}
            width="100%"
          >
            <InputField
              placeholder="Quantity"
              name="quantity"
              formik={formik}
            />
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            md={12}
          >
            <Box sx={{ width: { xs: "75%", sm: "100%", md: "94%" } }}>
              <MultilineInputField
                placeholder="Enter Description here"
                name="description"
                formik={formik}
              />
            </Box>
          </Grid>
          <Grid
            item
            sx={{ display: "flex", justifyContent: "center" }}
            xs={12}
            md={12}
          >
            <Box
              sx={{
                width: {
                  xs: "75%",
                  sm: "100%",
                  md: "94%",
                  display: "flex",
                  justifyContent: "flex-end",
                },
              }}
            >
              <Button
                type="submit"
                onClick={formik.handleSubmit}
                sx={{
                  fontFamily: "poppins",
                  width: { xs: "100%", sm: "306px" },
                  height: "58px",
                  borderRadius: { xs: "5px", sm: "10px" },
                  fontSize: { xs: 12, md: 10, lg: 14 },
                  bgcolor: "rgba(38,179, 240,1)  !important",
                  mb: { xs: 1, md: 0 },
                }}
                variant="contained"
              >
                Edit Product
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Index;
