import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  Paper,
  Button,
  Select,
  MenuItem,
  Checkbox,
} from "@mui/material";
import Residence from "../../../assets/images/Residence.png";
import Commercial from "../../../assets/images/Commercial.png";
import car from "../../../assets/images/car.png";
import shop from "../../../assets/images/shop.png";
import genral from "../../../assets/images/genral.png";
import mechine from "../../../assets/images/mechine.png";
import Subheading from "../../../utils/HeadingAdmin";
import Carousel from "../../../utils/Carousel";
import PurchaseCard from "./ProductCard";
import cat1 from "../../../assets/images/cat1.png";
import { CategoryAPI, ProductAPI } from "../../../api";
import Loader from "../../../utils/Loader";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { SelectAllOutlined } from "@mui/icons-material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import Modal from "./AddCategoryModal";
import EditModal from "./EditCategoryModal";
const { REACT_APP_HOST } = process.env;
const WonBids = [
  {
    id: 1,
    title: "item #1",
    image: cat1,
    count: "22",
    bidNo: "22",
    amount: "10000",
    price: "10000",
    year: "2002",
  },
  {
    id: 2,
    title: "item #2",
    image: cat1,
    count: "4",
    price: "20000",
    year: "2016",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 3,
    title: "item #3",
    image: cat1,
    count: "65",
    price: "700",
    year: "2020",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 4,
    title: "item #4",
    image: cat1,
    count: "70",
    price: "9000",
    year: "2002",
    bidNo: "22",
    amount: "10000",
  },
  {
    id: 5,
    title: "item #5",
    image: cat1,
    count: "1",
    price: "3000",
    year: "2009",
    bidNo: "22",
    amount: "10000",
  },
];
let Cats = [
  {
    image: Commercial,
    title: "Commercial",
    count: "99+",
  },
  {
    image: Residence,
    title: "Residence",
    count: "233",
  },

  {
    image: car,
    title: "Cars",
    count: "12",
  },
  {
    image: shop,
    title: "Shops",
    count: "99",
  },
  {
    image: genral,
    title: "Genral Items",
    count: "233",
  },
  {
    image: mechine,
    title: "Mechinery",
    count: "1",
  },
];

function AuctionCategories() {
  const [status, setStatus] = useState(1);
  const [selected, setSelected] = useState(1);
  const [categories, setCategories] = useState([]);
  const [productsByCat, setProductsByCat] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [checked, setChecked] = useState();
  const [checkedCat, setCheckedCat] = useState();
  const [change, setChange] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openEdit, setEditOpen] = React.useState(false);
  const handleEditOpen = () => setEditOpen(true);
  const handleEditClose = () => setEditOpen(false);
  let Navigate = useNavigate();
  const handleChange = (event) => {
    setChecked(event);
  };
  const handleCatChange = (event) => {
    console.log("event", event);
    setCheckedCat(event);
  };
  useEffect(() => {
    CategoryAPI.allCategories().then((res) => {
      setCategories(res?.data?.data);
      let newArr = res?.data.data?.filter((item) => item.id == selected);
      let newData = newArr[0]?.products?.filter(
        (product) => product.status == status
      );
      setProductsByCat(newData);
      setisLoading(false);
    });
  }, [change, deleted, open, openEdit]);

  useEffect(() => {
    let newArr = categories?.filter((item) => item.id == selected);
    let newData = newArr[0]?.products?.filter(
      (product) => product.status == status
    );
    setProductsByCat(newData);
    setChange(false);
    setDeleted(false);
  }, [selected, status, change, deleted]);
  const handleStatus = (event, newValue) => {
    setStatus(event.target.value);
  };

  function deleteProduct() {
    setisLoading(true);
    ProductAPI.deleteProduct(checked).then((res) => {
      setisLoading(false);
      toast.success("Product Deleted Successfully");
      setDeleted(true);
    });
  }

  function deleteCategory() {
    setisLoading(true);
    CategoryAPI.deleteCategory(checkedCat).then((res) => {
      setisLoading(false);
      toast.success("Category Deleted Successfully");
      setDeleted(true);
    });
  }
  return (
    <Box height="100%" pb={5} width="100%">
      <Box className={"centerRow"} sx={{ py: { md: 1 }, pb: { xs: 2, md: 0 } }}>
        <Subheading>Categories</Subheading>
      </Box>
      <Box className={"centerRow"}>
        <Box
          sx={{
            width: { xs: "60%", md: "45%" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button
            sx={{
              fontFamily: "poppins",
              // width: { xs: "100%", md: "60%" },
              width: { xs: "100%", sm: "120px" },
              height: "35px",
              borderRadius: { xs: "8px", sm: "10px" },
              fontSize: { xs: 12, md: 10, lg: 14 },
              bgcolor: "rgba(38,179, 240,1)  !important",
              mb: { xs: 1, md: 0 },
            }}
            variant="contained"
            onClick={() => handleOpen()}
          >
            Add
          </Button>
          <Button
            sx={{
              fontFamily: "poppins",
              // width: { xs: "100%", md: "60%" },
              width: { xs: "100%", sm: "120px" },
              height: "35px",
              borderRadius: { xs: "8px", sm: "10px" },
              fontSize: { xs: 12, md: 10, lg: 14 },
              bgcolor: "rgba(38,179, 240,1)  !important",
              mb: { xs: 1, md: 0 },
            }}
            variant="contained"
            onClick={() =>
              checkedCat
                ? handleEditOpen()
                : toast.warning("Please select a Category first")
            }
          >
            Edit
          </Button>
          <Button
            sx={{
              fontFamily: "poppins",
              // width: { xs: "100%", md: "60%" },
              width: { xs: "100%", sm: "120px" },
              height: "35px",
              borderRadius: { xs: "8px", sm: "10px" },
              fontSize: { xs: 12, md: 10, lg: 14 },
              bgcolor: "rgba(240,38, 38,1)  !important",
              mb: { xs: 1, md: 0 },
            }}
            variant="contained"
            onClick={() =>
              checkedCat
                ? deleteCategory()
                : toast.warning("Please select a Category first")
            }
          >
            Delete
          </Button>
        </Box>
      </Box>
      <Modal
        open={open}
        handleClose={handleClose}
        // id={item.id}
      />
      <EditModal
        open={openEdit}
        handleClose={handleEditClose}
        id={checkedCat}
      />
      <Grid container spacing={2} px={"5%"}>
        {categories?.map((cat, index) => {
          return (
            <Grid
              item
              key={cat?.name}
              xs={6}
              sm={4}
              md={4}
              lg={2}
              display="flex"
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems="center"
            >
              <Paper
                elevation={3}
                sx={{
                  cursor: "pointer",
                  width: {
                    xs: "80px",
                    md: selected == cat?.id ? "140px" : "120px",
                  },
                  height: {
                    xs: "80px",
                    md: selected == cat?.id ? "140px" : "120px",
                  },
                  bgcolor:
                    selected == cat?.id
                      ? "rgba(61,197, 255,0.75) "
                      : "rgba(158,228, 253,0.4) !important",
                  borderRadius: "10px",
                  position: "relative",
                  p: "5%",
                }}
              >
                <Box bgcolor="white" width="auto">
                  <Checkbox
                    icon={
                      <Box
                        width="23px"
                        height="23px"
                        bgcolor="white"
                        border="2px solid #0a8241"
                      />
                    }
                    checkedIcon={
                      <CheckBoxOutlinedIcon
                      // fontSize="80px !important"
                      // sx={{ width: 40, height: 40 }}
                      />
                    }
                    sx={{
                      ".MuiSvgIcon-root": {
                        fill: "#0a8241",
                        fontSize: 34,
                        fontWeight: 100,
                      },
                      position: "absolute",
                      top: -10,
                      left: -20,
                      width: 40,
                      height: 20,
                    }}
                    checked={cat?.id == checkedCat ? true : false}
                    onClick={() => handleCatChange(cat?.id)}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                </Box>
                <Box
                  sx={{
                    width: { xs: "40px", md: "50px" },
                    height: { xs: "40px", md: "50px" },
                    borderRadius: "50%",
                    bgcolor: "red",
                    position: "absolute",
                    bgcolor: "rgba(38,179, 240,1) !important",
                    transformOrigin: "90% 40% !important",
                    top: -20,
                    right: -20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "white" }}>
                    {cat?.products?.length}
                  </Typography>
                </Box>
                <Box
                  onClick={() => {
                    // navigate("/auction");
                    setSelected(cat?.id);
                  }}
                  component="img"
                  sx={{
                    width: "100%",
                    height: "100%",
                    // width: { xs: "50%", sm: "60%", md: "100%" },
                    // heigth: { xs: "50%", sm: "60%", md: "100%" },
                    display: "block",
                    overflow: "hidden",
                    objectFit: "contain",
                  }}
                  src={`${REACT_APP_HOST}${cat?.image?.url}`}
                />
              </Paper>

              <Typography
                // variant="h5"
                // align="center"
                color="#024861"
                fontFamily="Raleway, sans-serif"
                fontWeight="bold"
                sx={{ fontSize: { xs: 18, md: 16, xl: 28 }, py: 2 }}
              >
                {cat?.name}
              </Typography>
            </Grid>
          );
        })}
      </Grid>
      <Box className={"centerRow"} sx={{ pt: { md: 1 }, pb: { xs: 2, md: 0 } }}>
        <Subheading>Auction Products</Subheading>
      </Box>
      <Box className={"centerRow"}>
        <Box
          sx={{
            width: { xs: "60%", md: "45%" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "space-around",
            alignItems: "center",
            mb: 5,
          }}
        >
          <Button
            sx={{
              fontFamily: "poppins",
              // width: { xs: "100%", md: "60%" },
              width: { xs: "100%", sm: "120px" },
              height: "35px",
              borderRadius: { xs: "8px", sm: "10px" },
              fontSize: { xs: 12, md: 10, lg: 14 },
              bgcolor: "rgba(38,179, 240,1)  !important",
              mb: { xs: 1, md: 0 },
            }}
            variant="contained"
            onClick={() => Navigate("/admin/add-product")}
          >
            Add
          </Button>
          <Button
            sx={{
              fontFamily: "poppins",
              // width: { xs: "100%", md: "60%" },
              width: { xs: "100%", sm: "120px" },
              height: "35px",
              borderRadius: { xs: "8px", sm: "10px" },
              fontSize: { xs: 12, md: 10, lg: 14 },
              bgcolor: "rgba(38,179, 240,1)  !important",
              mb: { xs: 1, md: 0 },
            }}
            variant="contained"
            onClick={() =>
              checked
                ? Navigate(`/admin/edit-product/${selected}/${checked}`)
                : toast.warning("Please select a product first")
            }
          >
            Edit
          </Button>
          <Button
            sx={{
              fontFamily: "poppins",
              // width: { xs: "100%", md: "60%" },
              width: { xs: "100%", sm: "120px" },
              height: "35px",
              borderRadius: { xs: "8px", sm: "10px" },
              fontSize: { xs: 12, md: 10, lg: 14 },
              bgcolor: "rgba(240,38, 38,1)  !important",
              mb: { xs: 1, md: 0 },
            }}
            variant="contained"
            onClick={() =>
              checked
                ? deleteProduct()
                : toast.warning("Please select a product first")
            }
          >
            Delete
          </Button>

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status}
            // variant="outlined"
            size="small"
            fullWidth
            sx={{
              fontFamily: "poppins !important",
              textAlign: "center",
              height: "35px",
              borderRadius: { xs: "8px", sm: "10px" },
              px: "14px",
              py: { xs: 0, sm: "1px" },
              width: { xs: "100%", sm: "120px", md: "150px" },
              color: "white",
              bgcolor: "rgba(38,179, 240,1) ",
              // boxShadow: " 0px 4px 6px 0px rgba(0,0,0,0.3)",
              ".MuiMenuItem-root": {
                fontFamily: "poppins !important",
              },
            }}
            onChange={handleStatus}
          >
            <MenuItem value={1}> {`Active`} </MenuItem>
            <MenuItem value={0}>{`In Active`}</MenuItem>
          </Select>
        </Box>
      </Box>
      <Box sx={{ px: { xs: 3, md: 1 } }}>
        <Loader active={isLoading} />
        <Carousel>
          {productsByCat?.length > 0 ? (
            productsByCat?.map((item, index) => (
              <PurchaseCard
                index={index}
                cat={selected}
                checked={checked}
                handleChange={handleChange}
                setChecked={setChecked}
                item={item}
                setChange={setChange}
              ></PurchaseCard>
            ))
          ) : (
            <Typography sx={{ fontWeight: "bold", fontSize: "18px", py: 12 }}>
              No products in this category
            </Typography>
          )}
        </Carousel>
      </Box>
    </Box>
  );
}

export default AuctionCategories;
