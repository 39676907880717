import React, { useState, useEffect } from "react";
import { Box, Button, Paper, Typography, Grid } from "@mui/material";
import Buyer from "../../../assets/images/Buyer.png";
import Card from "./Transectioncard";
import { BuyerAPI } from "../../../api";
import Loader from "../../../utils/Loader";
const { REACT_APP_HOST } = process.env;

let users = [
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
  {
    name: "Hasnain",
    email: "hasnain@gmail.com",
    products: 4,
    transactions: 5,
  },
];

function BuyerSeller() {
  const [buyers, setBuyers] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [exist, setExist] = useState(false);

  useEffect(() => {
    BuyerAPI.buyerTransections().then((res) => {
      setBuyers(res.data.data);
      setisLoading(false);
    });
  }, []);
  return (
    <Box pb={5}>
      <Box className={"centerRow"} sx={{ py: { md: 5 }, pb: { xs: 2, md: 5 } }}>
        <Box
          sx={{ width: "100px", height: "100px", mt: 1 }}
          component="img"
          src={Buyer}
        />
      </Box>
      <Loader active={isLoading} />

      <Grid
        container
        spacing={3}
        sx={{
          px: { xs: 3, md: 1 },
          py: { xs: 3, md: 1 },
          maxHeight: "100vh",
          minHeight: "100%",
          overflowY: { md: "scroll" },
          "&::-webkit-scrollbar": {
            width: "10px",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px #B9EDFF",
            webkitBoxShadow: "inset 0 0 6px #26B3F0",
            border: "1px solid #26B3F0",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#26B3F0",
            outline: "1px solid slategrey",
          },
        }}
      >
        {buyers?.map((buyer, index) => {
          return buyer?.transactions?.map((item) => {
            return (
              <Grid item xs={12} sm={6} md={4}>
                {setExist(true)}
                <Card item={{ item: item, buyer: buyer }} />
              </Grid>
            );
          });
        })}
        {exist == false ? (
          <Typography
            sx={{
              textAlign: "center",
              width: "100%",
              pl: 3,
              py: 5,
              fontWeight: "bold",
            }}
          >
            No Transactions
          </Typography>
        ) : null}
      </Grid>
    </Box>
  );
}

export default BuyerSeller;
