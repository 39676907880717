import React from "react";
import { Box, Select, MenuItem } from "@mui/material";
import dropdown from "../../../assets/icons/dropdown.png";
function SelectField({ formik, array, name, noValidationText, ...props }) {
  let { value, setValue } = props;
  const handleChange = (event) => {
    console.log("event", event.target.value);
    formik.setFieldValue(name, event.target.value);
  };
  return (
    <div>
      <Select
        // IconComponent={() => <Box component="img" src={dropdown} />}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        size="small"
        fullWidth
        sx={{
          width: { xs: "310px", sm: "290px", md: "310px" },
          fontFamily: "Raleway, sans-serif",
          fontSize: "18px",
          ".MuiInputBase-input": {
            fontFamily: "Raleway, sans-serif",
            py: "12px !important",
            //   px: "0 !important",
            color: "white",
            textAlign: "center",
          },
          boxShadow: " 0px 4px 6px 0px rgba(0,0,0,0.3)",
          bgcolor: "#26B3F0",
          borderRadius: "5px !important",
          color: "white",
          textAlign: "center",
          ".MuiMenuItem-root": {
            fontFamily: "Raleway, sans-serif",
            fontSize: "18px",
          },
        }}
        name={name}
        // defaultValue={1}
        value={formik?.values[name]}
        onChange={handleChange}
        onBlur={formik?.handleBlur}
        error={formik?.touched[name] && Boolean(formik.errors[name])}
        helperText={
          !noValidationText && formik?.touched[name] && formik?.errors[name]
        }
      >
        {array?.length > 0 &&
          array?.map((item) => {
            return (
              <MenuItem key={item?.id} value={item?.id}>
                {item?.name}
              </MenuItem>
            );
          })}
        {/* <MenuItem value={2}>{`Category 2`}</MenuItem> */}
      </Select>
    </div>
  );
}

export default SelectField;
